import { useGitHubSelection } from '../hooks/useGitHubHooks';
import { RepositorySelect } from './RepositorySelect';
import { GitHubSelectorProps } from './types';

export const GitHubSelector = ({
	integrationId,
	selectedRepo,
	onSelect,
	onFocus,
	error,
	isRequired = false,
	isIntegrationValid,
	isValidProviderToken,
	isTouched,
	token,
}: GitHubSelectorProps) => {
	const {
		repositories,
		isLoading,
		handleSearch,
		handleFetchNextPage,
		isFetching,
		error: githubError,
	} = useGitHubSelection({
		integrationId,
		repository: selectedRepo || '',
		enabled: isIntegrationValid,
		isValidProviderToken,
		token,
	});

	return (
		<RepositorySelect
			repositories={repositories}
			value={selectedRepo ?? ''}
			onChange={(option) => {
				const repo = repositories.find((r) => r.name === option.value);
				if (repo) {
					onSelect(repo.name, repo);
				}
			}}
			onFocus={() => onFocus && onFocus('repository')}
			isLoading={isLoading || isFetching}
			error={error}
			apiError={githubError?.message}
			isRequired={isRequired}
			enabled={isIntegrationValid}
			isTouched={isTouched}
			onInputChange={handleSearch}
			onFetchNextPage={handleFetchNextPage}
		/>
	);
};
