import { MutationError } from '../../../shared/types/validation';
import { UseToastOptions } from '@chakra-ui/react';

/**
 * Handles submission errors for project updates by displaying appropriate toast notifications.
 * Specifically handles token validation errors by clearing the source control integration,
 * and provides fallback error handling for other error types.
 *
 * @param error - The error object thrown during submission
 * @param options - Configuration object containing toast and field update functions
 * @param options.toast - Function to display toast notifications
 * @param options.setFieldValue - Function to update form field values
 */
export const handleSubmitError = (
	error: unknown,
	options: {
		toast: (options: UseToastOptions) => void;
		setFieldValue: (field: string, value: string) => void;
	},
) => {
	const mutationError = error as MutationError;
	const { toast, setFieldValue } = options;

	// Handle token validation error
	if (mutationError.response?.data?.errors?.token) {
		toast({
			status: 'error',
			description: mutationError.response.data.errors.token[0],
			duration: 6000,
			isClosable: true,
		});
		setFieldValue('sourceControlIntegrationId', '');
		return;
	}

	// Handle other errors
	toast({
		status: 'error',
		description:
			mutationError.response?.data?.message ??
			mutationError.response?.data?.detail ??
			mutationError.message ??
			'An error occurred',
	});
	console.error('Update failed:', error);
};
