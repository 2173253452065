import { EnvironmentsPage } from './EnvironmentsPage';
import { EnvironmentsPageLegacy } from './legacy-pages/EnvironmentsPageLegacy';
import { useGetEnvType } from '../hooks/useGetEnvType';

export const ProjectEnvironments: React.FC = () => {
	const { isLegacyEnvsRoute } = useGetEnvType();

	if (isLegacyEnvsRoute) return <EnvironmentsPageLegacy />;

	return <EnvironmentsPage />;
};
