import { FC, useEffect, useMemo, useState } from 'react';
import { Box, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { t } from '@transifex/native';
import {
	allDeployments,
	deployments as enDeployments,
} from '../../../../@xmcloud/core/messages/en';
import { handlePush, handleTo } from '../../../../@xmcloud/utils/helpers';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import {
	Icon,
	IconText,
	Link,
	LoadingSkeleton,
	RenderPlaceholder,
	RowActions,
	Table,
	TableRef,
} from '../../../shared-components';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import {
	DeployProcessStatus,
	IGetDeploymentResponse,
} from '../../../models/deploymentModel';
import CancelDeployment from '../../project/deployment-logs/CancelDeployment';
import DeployBuildOtherEnv from '../../project/components/DeployBuildOtherEnv';
import { statusIconsTextList } from '../../project/columns/deployments';
import { useGetAllDeployments } from '../../../services/deployments';
import { StatusHeader } from '../../../shared-components/popover/DeploymentsStatusHeader';
import {
	ColumnDef,
	PaginationState,
	createColumnHelper,
} from '@tanstack/react-table';
import config from '../../../config/config';
import { useQueryClient } from 'react-query';
import { useAuthenticatedAxios } from '../../../apiUtils/AxiosProvider';
import { DeploymentFilter } from '../../../../@xmcloud/types';
import { useCreateProjectV3 } from 'app/pages/create-project/old-components/useCreateProjectV3';
import { EnvironmentTypesEnum } from 'app/pages/create-project/helpers';
import { mdiFeather, mdiMonitor } from '@mdi/js';

const {
	project,
	projectId,
	environment,
	environmentId,
	date,
	progress,
	queued,
	complete,
	failed,
	skipped,
	type,
} = allDeployments;

const { viewLogs, cancel: canceltxt, deployBuildArtifact } = enDeployments;
const { allDeployments_v3 } = config.deployments;

const { Skipped, NotStarted, InProgress, Complete } = DeployProcessStatus;
const { EH, CM } = EnvironmentTypesEnum;

const {
	oldDeployments: oldDeploymentsPath,
	deployments: deploymentsPath,
	deploymentLogs: deploymentLogsPath,
	oldDeploymentLogs: oldDeploymentLogsPath,
	environments: environmentsPath,
	cmEnvironments: cmEnvironmentsPath,
	ehEnvironments: ehEnvironmentsPath,
} = goTo;

const headers = [
	'Type',
	'Envrionment',
	'Environment ID',
	'Project',
	'Date',
	'Status',
	'',
];
const legacyHeaders = [
	'name',
	'Project ID',
	'Envrionment',
	'Environment ID',
	'Date',
	'Status',
	'',
];

interface Props {
	filter: DeploymentFilter;
	tableRef: TableRef;
}

const DeploymentsTable: FC<Props> = ({ filter: queryFilter, tableRef }) => {
	const [showCancelDeploymentModal, setshowCancelDeploymentModal] =
		useState(false);
	const [detail, setDetail] = useState({
		deploymentId: '',
		environmentId: '',
		projectId: '',
	});
	const [deployToOtherEnvModal, setDeployToOtherEnvModal] = useState(false);
	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});
	const { enableCreateProjectV3 } = useCreateProjectV3();

	const pagination = useMemo(
		() => ({
			pageIndex,
			pageSize,
		}),
		[pageIndex, pageSize],
	);

	const queryClient = useQueryClient();
	const axiosContext = useAuthenticatedAxios();

	const { data, isLoading, isFetching, isPreviousData } =
		useGetAllDeployments(pageIndex + 1, pageSize, queryFilter);

	const deployments = (data?.data.data as IGetDeploymentResponse[]) || [];

	const totalCount = data?.data?.totalCount || 0;

	const columns: ColumnDef<IGetDeploymentResponse, any>[] =
		useDeploymentColumns(
			setDetail,
			setshowCancelDeploymentModal,
			setDeployToOtherEnvModal,
		);

	useEffect(() => {
		const hasMore = totalCount > pageIndex * pageSize;
		const { url, queryKey } = allDeployments_v3;

		if (!isPreviousData && hasMore) {
			queryClient.prefetchQuery({
				queryKey: queryKey(pageIndex + 1, queryFilter),
				queryFn: () =>
					axiosContext.get(url(pageIndex + 1, pageSize, queryFilter)),
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPreviousData, pageIndex]);

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} mt={12} />;
	if (deployments.length === 0)
		return <RenderPlaceholder item="Deployments" />;

	return (
		<>
			<Table
				{...{
					columns,
					pageCount: Math.ceil(totalCount / pageSize),
					pagination,
					tableData: deployments,
					dataTestId: 'paginated-deployments-table',
					initialSort: [],
					isFetching,
					manualPagination: true,
					onPaginationChange: setPagination,
					ref: tableRef,
					headers: enableCreateProjectV3 ? headers : legacyHeaders,
				}}
			/>
			{showCancelDeploymentModal && (
				<CancelDeployment
					environmentId={detail.environmentId}
					deploymentId={detail.deploymentId}
					openModal={showCancelDeploymentModal}
					setOpenModal={setshowCancelDeploymentModal}
				/>
			)}
			{deployToOtherEnvModal && (
				<DeployBuildOtherEnv
					openModal={deployToOtherEnvModal}
					setOpenModal={setDeployToOtherEnvModal}
					environmentId={detail.environmentId}
					deploymentId={detail.deploymentId}
					projectId={detail.projectId}
				/>
			)}
		</>
	);
};

export default DeploymentsTable;

const columnHelper = createColumnHelper<IGetDeploymentResponse>();

export const useDeploymentColumns = (
	setDetail: (a: any) => void,
	setshowCancelDeploymentModal: (a: boolean) => void,
	setDeployToOtherEnvModal: (a: boolean) => void,
) => {
	const { enableCreateProjectV3 } = useCreateProjectV3();
	return useMemo(
		() => [
			...(enableCreateProjectV3
				? [
						columnHelper.accessor('environmentType', {
							header: () => t(type),
							cell: (info) => {
								const envType = info.getValue();
								const isEh = envType === EH;
								return (
									<Icon
										path={isEh ? mdiFeather : mdiMonitor}
										color={isEh ? 'purple' : 'cyan'}
										layerStyle="icon.subtle"
										boxSize="icon.2xs"
									/>
								);
							},
							enableSorting: false,
							maxSize: 0,
						}),
					]
				: [
						columnHelper.accessor('projectName', {
							header: () => t(project),
							cell: ({
								row: {
									original: {
										projectName,
										projectId,
										environmentId,
										environmentType,
									},
								},
							}) => (
								<Link
									text={projectName || ''}
									to={handleTo(
										environmentType
											? deploymentsPath(
													projectId,
													environmentId,
													environmentType,
												)
											: oldDeploymentsPath(
													projectId,
													environmentId,
												),
									)}
								/>
							),
							enableSorting: false,
						}),
					]),
			...(enableCreateProjectV3
				? [
						columnHelper.accessor('environmentName', {
							header: () => t(environment),
							cell: ({
								row: {
									original: {
										environmentName,
										environmentId,
										projectId,
										environmentType,
									},
								},
							}) => (
								<Link
									text={environmentName || ''}
									to={handleTo(
										environmentType
											? deploymentsPath(
													projectId,
													environmentId,
													environmentType,
												)
											: oldDeploymentsPath(
													projectId,
													environmentId,
												),
									)}
									whiteSpace="wrap"
									noOfLines={3}
									maxW="xs"
								/>
							),
							enableSorting: false,
						}),
					]
				: [
						columnHelper.accessor('projectId', {
							header: () => t(projectId),
							cell: (info) => info.getValue(),
							enableSorting: false,
						}),
					]),
			...(enableCreateProjectV3
				? [
						columnHelper.accessor('environmentId', {
							header: () => t(environmentId),
							cell: (info) => info.getValue(),
							enableSorting: false,
						}),
					]
				: [
						columnHelper.accessor('environmentName', {
							header: () => t(environment),
							cell: (info) => (
								<Text
									whiteSpace={['nowrap', 'wrap']}
									maxW={['50%', '11vw']}
									isTruncated
								>
									{info.getValue()}
								</Text>
							),
							enableSorting: false,
						}),
					]),
			...(enableCreateProjectV3
				? [
						columnHelper.accessor('projectName', {
							header: () => t(project),
							cell: ({
								row: {
									original: {
										projectName,
										projectId,
										environmentType,
									},
								},
							}) => {
								const isEh = environmentType === EH;
								const isCm = environmentType === CM;
								return (
									<Link
										text={projectName || ''}
										to={handleTo(
											isEh
												? ehEnvironmentsPath(projectId)
												: isCm
													? cmEnvironmentsPath(
															projectId,
														)
													: environmentsPath(
															projectId,
														),
										)}
										whiteSpace="wrap"
										noOfLines={3}
										maxW="xs"
									/>
								);
							},
							enableSorting: false,
							maxSize: 56,
						}),
					]
				: [
						columnHelper.accessor('environmentId', {
							header: () => t(environmentId),
							cell: (info) => info.getValue(),
							enableSorting: false,
						}),
					]),
			columnHelper.accessor('createdAt', {
				header: () => t(date),
				cell: (info) => getReadableDate(info.getValue()),
				enableSorting: false,
			}),
			columnHelper.accessor('calculatedStatus', {
				header: () => <StatusHeader allStatus={statusDescriptions} />,
				cell: (info) => (
					<CurrentIconText calculatedStatus={info.getValue()} />
				),
				enableSorting: false,
			}),
			columnHelper.display({
				id: 'actions',
				cell: ({
					row: {
						original: {
							calculatedStatus,
							projectId,
							environmentId,
							id,
							environmentType,
						},
					},
				}) =>
					calculatedStatus === Skipped ? (
						<Box h="10" />
					) : (
						<RowActions ariaLabel="variables-table-action-menu">
							<MenuList>
								<MenuItem
									onClick={() => {
										handlePush(
											environmentType
												? deploymentLogsPath(
														projectId,
														environmentId,
														id,
														environmentType,
													)
												: oldDeploymentLogsPath(
														projectId,
														environmentId,
														id,
													),
										);
									}}
								>
									{t(viewLogs)}
								</MenuItem>
								{Boolean(
									calculatedStatus === InProgress ||
										calculatedStatus === NotStarted,
								) && (
									<MenuItem
										onClick={() => {
											setDetail({
												environmentId,
												deploymentId: id,
												projectId,
											});
											setshowCancelDeploymentModal(true);
										}}
									>
										{t(canceltxt)}
									</MenuItem>
								)}
								{calculatedStatus === Complete && (
									<MenuItem
										onClick={() => {
											setDetail({
												environmentId,
												deploymentId: id,
												projectId,
											});
											setDeployToOtherEnvModal(true);
										}}
									>
										{t(deployBuildArtifact)}
									</MenuItem>
								)}
							</MenuList>
						</RowActions>
					),
				maxSize: 0,
			}),
		],
		[
			enableCreateProjectV3,
			setDeployToOtherEnvModal,
			setDetail,
			setshowCancelDeploymentModal,
		],
	);
};

export const statusDescriptions = [
	{ s: 1, desc: progress },
	{ s: 2, desc: complete },
	{ s: 0, desc: queued },
	{ s: 3, desc: failed },
	{ s: 4, desc: skipped },
];

export const CurrentIconText = ({
	calculatedStatus,
	textColor,
	xsText,
	lineHeight,
}: {
	calculatedStatus: number;
	textColor?: string;
	xsText?: string;
	lineHeight?: number;
}) => {
	const status = statusIconsTextList[calculatedStatus];
	return (
		<IconText
			{...{
				text: status.text,
				...(status?.isIndeterminate && {
					isIndeterminate: true,
				}),
				...(status?.IconName && {
					IconName: status.IconName,
				}),
				...(status?.iconColor && {
					iconColor: status.iconColor,
				}),
				...(textColor && { textColor }),
				...(xsText && { xsText }),
				...(lineHeight && { lineHeight }),
				noOfLines: 3,
			}}
		/>
	);
};
