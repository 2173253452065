import { SourceControlType } from '@xmcloud/types';
import { useValidateRepository, useProviderToken } from '../hooks';

export const useIntegrationValidation = (
	selectedIntegrationId: string,
	currentProvider: SourceControlType,
) => {
	const { data: validationData, isLoading: validationLoading } =
		useValidateRepository(selectedIntegrationId, !!selectedIntegrationId);
	const validationIntegration = validationData?.data;

	const { provider: tokenProvider, token: integrationToken } =
		useProviderToken(
			selectedIntegrationId,
			validationData?.data.isValid ?? false,
		);

	const isValidToken = tokenProvider === currentProvider;

	const integrationError = {
		integrationValid: {
			isValid:
				(!validationLoading && validationIntegration?.isValid) ?? false,
			errorMsg: validationIntegration?.message ?? '',
		},
		loading: validationLoading,
	};

	const hasActiveValidationError = (integrationId: string) => {
		if (integrationId === '') {
			return false;
		}
		return (
			!integrationError.loading &&
			!integrationError.integrationValid.isValid
		);
	};

	return {
		integrationError,
		isValidToken,
		integrationToken,
		hasActiveValidationError,
	};
};
