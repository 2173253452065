interface RepositoryInfo {
	title: string;
	description: string;
	hasWarning: boolean;
}

export function getRepositoryInfo(
	repository: string | null,
	sourceControlIntegrationId: string | null,
): RepositoryInfo {
	const hasRepository = !!repository && !!sourceControlIntegrationId;

	if (hasRepository) {
		return {
			title: 'Default repository',
			description:
				'The following repository is set by default when you create a new CM environment or Editing Host inside this project, but you can override it with a different repository for each environment.',
			hasWarning: false,
		};
	} else {
		return {
			title: 'No repository set',
			description:
				'Your project currently has no associated repository, but you can add one now by selecting a provider to start configuring it. If a repository isn’t needed, you can leave it unconfigured and still modify the project name.',
			hasWarning: true,
		};
	}
}
