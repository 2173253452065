import React from 'react';
import { SelectFormControl } from 'app/shared-components';
import { RepositorySelectProps } from './types';
import { t } from '@transifex/native';

export const RepositorySelect = React.memo(
	({
		repositories,
		value,
		onChange,
		onFocus,
		isLoading,
		error,
		isRequired,
		enabled = true,
		isTouched,
		onInputChange,
		onFetchNextPage,
		apiError,
	}: RepositorySelectProps) => (
		<SelectFormControl
			label="Repository"
			name="repository"
			currentValue={value}
			onChange={(option) => onChange(option)}
			onInputChange={(input: string) => {
				onInputChange && onInputChange(input);
			}}
			onMenuScrollToBottom={() => {
				onFetchNextPage && onFetchNextPage();
			}}
			onFocus={onFocus}
			options={repositories.map((repo) => ({
				value: repo.name,
				label: repo.name,
			}))}
			isLoading={isLoading}
			error={error}
			isInvalid={(Boolean(error) && isTouched) || Boolean(apiError)}
			isDisabled={!enabled}
			isRequired={isRequired}
			pt={0}
			noOptionsMessage={() => t('No repositories found')}
		/>
	),
);
