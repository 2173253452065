import React from 'react';
import {
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	Button,
	Wrap,
} from '@chakra-ui/react';
import { mdiUndo } from '@mdi/js';
import { IRepositoryIntegrationDetails } from 'app/models/repositoriesModel';
import { t } from '@transifex/native';
import { connections } from '@xmcloud/core/messages/en';
import { Icon } from 'app/shared-components';

const { github, ado } = connections;

interface ProviderChangeAlertProps {
	connectionInfo: string;
	onSwitchBack: () => void;
	repository: IRepositoryIntegrationDetails | null;
}

export const ProviderChangeAlert: React.FC<ProviderChangeAlertProps> = ({
	connectionInfo,
	onSwitchBack,
	repository,
}) => (
	<Alert status="info" variant="subtle" minW="100%" mt={4}>
		<AlertIcon />
		<Wrap w="full">
			<AlertTitle>{t('Changing Provider')}</AlertTitle>
			<AlertDescription>{connectionInfo}</AlertDescription>
			<Button
				leftIcon={<Icon path={mdiUndo} />}
				variant="link"
				onClick={onSwitchBack}
			>
				{t('Switch back to {provider}', {
					provider: repository?.provider === 2 ? ado : github,
				})}
			</Button>
		</Wrap>
	</Alert>
);
