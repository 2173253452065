import {
	mdiCogOutline,
	mdiConnection,
	mdiSwapHorizontal,
	mdiBookOpenPageVariantOutline,
	mdiHeartPulse,
	mdiKey,
	mdiRocketLaunchOutline,
	mdiFolderOutline,
} from '@mdi/js';
import { t } from '@transifex/native';
import { app } from '../../core/messages/en';
import { mainPaths } from '../../core/routes/paths';
import { handleTo } from '../../utils/helpers';
import { isActive } from '../../utils/routerUtils';
import { Link } from 'react-router-dom';
import { Button, Stack } from '@chakra-ui/react';
import StatusIcons from './Icons';
import { useBetaFeatures, useSharedState } from '../../hooks';
import { CONTENT_TRANSFER } from '../../hooks/context/useSharedState';
import { Icon } from '../../../app/shared-components';
import { useState } from 'react';
const {
	projects,
	settings,
	systemStatus,
	deployments,
	credentials,
	contenttransfer,
	documentation,
	connections,
} = mainPaths;

const NavigationVertical: React.FC = () => {
	const [rerender, setRerender] = useState(false);
	const { contentTransferExpFeat } = useBetaFeatures();
	const { state: contentTransferEnabled } = useSharedState(
		CONTENT_TRANSFER,
		contentTransferExpFeat,
	);

	return (
		<Stack spacing="1" m="-2">
			{navigationResources
				.filter(
					(n) =>
						n.label !== app.contenttransfer ||
						contentTransferEnabled,
				)
				.map(({ label, active, icon, to }) => (
					<Button
						isActive={active() ? true : false}
						variant="navigation"
						leftIcon={<Icon path={icon} />}
						{...(icon === mdiHeartPulse && {
							rightIcon: <StatusIcons />,
						})}
						justifyContent="flex-start"
						as={Link}
						to={handleTo(to)}
						key={label}
						onClick={() => setRerender(!rerender)}
					>
						{t(label)}
					</Button>
				))}
		</Stack>
	);
};

export default NavigationVertical;

export const navigationResources = [
	{
		label: app.projects,
		to: projects,
		active: () =>
			window.location.pathname === projects ||
			isActive(projects.slice(0, -1)),
		icon: mdiFolderOutline,
	},
	{
		label: app.deployments,
		to: deployments,
		active: () => isActive(deployments),
		icon: mdiRocketLaunchOutline,
	},
	{
		label: app.credentials,
		to: `${credentials}/organization`,
		active: () => isActive(credentials),
		icon: mdiKey,
	},
	{
		label: app.connections,
		to: `${connections}/sourcecontrol`,
		active: () => isActive(connections),
		icon: mdiConnection,
	},
	{
		label: app.contenttransfer,
		to: contenttransfer,
		active: () => isActive(contenttransfer),
		icon: mdiSwapHorizontal,
	},
	{
		label: app.documentation,
		to: documentation,
		active: () => isActive(documentation),
		icon: mdiBookOpenPageVariantOutline,
	},
	{
		label: app.systemStatus,
		to: systemStatus,
		active: () => isActive(systemStatus),
		icon: mdiHeartPulse,
	},
	{
		label: app.settings,
		to: settings,
		active: () => isActive(settings),
		icon: mdiCogOutline,
	},
];
