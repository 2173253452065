export const PROJECT_FORM_FIELDS = {
	REPOSITORY: 'repository',
	REPOSITORY_ID: 'repositoryId',
	ADO_ORGANIZATION: 'adoOrganization',
	ADO_PROJECT_NAME: 'adoProjectName',
	ADO_REPOSITORY: 'adoRepository',
} as const;

export const REQUIRED_FIELDS = {
	[PROJECT_FORM_FIELDS.REPOSITORY]: true,
	[PROJECT_FORM_FIELDS.REPOSITORY_ID]: true,
	[PROJECT_FORM_FIELDS.ADO_ORGANIZATION]: true,
	[PROJECT_FORM_FIELDS.ADO_PROJECT_NAME]: true,
	[PROJECT_FORM_FIELDS.ADO_REPOSITORY]: true,
} as const;
