import { useAuthenticatedAxios } from 'app/apiUtils/AxiosProvider';
import config from 'app/config/config';
import { IValidateResponse } from 'app/models/projectModel';
import { IRepositoryIntegrationDetails } from 'app/models/repositoriesModel';
import {
	useGetRepositoryIntegration,
	useValidateSourceControlIntegrationV2,
} from 'app/services/repositories';
import { useQueryClient } from 'react-query';

/**
 * Custom hook to validate a repository integration and provide refetch capabilities
 * @param id - The integration ID to validate
 * @param enabled - Flag to enable/disable the validation query
 * @returns Object containing validation data, loading state, error state, and refetch functions
 */
export const useValidateRepository = (id: string, enabled: boolean = true) => {
	const queryClient = useQueryClient();
	const axiosInstance = useAuthenticatedAxios();
	const { data, isError, error, ...rest } =
		useValidateSourceControlIntegrationV2({
			integrationId: id,
			enabled,
		});

	const refetchValidationWithId = async (newId: string) => {
		const { url, queryKey } = config.repository.validate_integration_V2;

		const fetchValidation = async (id: string) => {
			const response = await axiosInstance.get<IValidateResponse>(
				url(id),
			);

			return response.data;
		};

		await queryClient.invalidateQueries({
			queryKey: queryKey(id),
		});

		const result = await fetchValidation(newId);
		return result;
	};

	return {
		data,
		isLoading: rest.isLoading,
		error,
		refetch: rest.refetch,
		refetchValidationWithId,
	};
};

/**
 * Custom hook to fetch and manage repository integration details
 * @param params Object containing:
 * @param params.id - The repository integration ID
 * @param params.enabled - Flag to enable/disable the query
 * @returns Object containing repository data, loading state, error state, and refetch function
 */
export const useRepository = ({
	id,
	enabled,
}: {
	id: string;
	enabled: boolean;
}) => {
	const queryClient = useQueryClient();
	const result = useGetRepositoryIntegration(id, enabled);
	const axiosInstance = useAuthenticatedAxios();

	const refetchWithId = async (newId: string) => {
		const { url, queryKey } = config.repository.get_repo_integration;

		const fetchRepository = async (id: string) => {
			const response =
				await axiosInstance.get<IRepositoryIntegrationDetails>(url(id));
			return response.data;
		};

		// Invalidate the old query
		await queryClient.invalidateQueries({
			queryKey: queryKey(id),
		});

		// Fetch and return the new data
		const newData = await queryClient.fetchQuery({
			queryKey: queryKey(newId),
			queryFn: () => fetchRepository(newId),
		});

		return newData;
	};

	return {
		...result,
		refetchRepoWithId: refetchWithId,
	};
};
