import { FC, useState } from 'react';
import { app, settings } from '../../../@xmcloud/core/messages/en';
import { HStack, Stack } from '@chakra-ui/react';
import {
	AlertDialogConfirmationModal,
	HeadingSm,
	InfoIcon,
	XMCSH3,
	Text,
} from '../../shared-components';
import { isPhoneDevice } from '../../../@xmcloud/utils/helpers';
import { BetaFeaturesPage } from './BetaFeaturesPage';

const { betaFeatures, betaInfo } = settings;
const { settings: settingstxt, close: closetxt } = app;

export const SettingsPage: FC = () => {
	const [openInfo, setOpenInfo] = useState(false);

	return (
		<Stack gap={4}>
			<XMCSH3 title={settingstxt} />
			<HStack>
				<HeadingSm text={betaFeatures} />
				<InfoIcon
					label={betaInfo}
					isDisabled={isPhoneDevice}
					onClick={() => isPhoneDevice && setOpenInfo(true)}
				/>
			</HStack>
			<BetaFeaturesPage />
			{openInfo && (
				<AlertDialogConfirmationModal
					{...{
						isOpen: openInfo,
						onClose: () => setOpenInfo(false),
						shouldShowActionBtn: false,
						rightButtonText: closetxt,
					}}
				>
					<Text mt="4" text={betaInfo} />
				</AlertDialogConfirmationModal>
			)}
		</Stack>
	);
};
