import { Box, Stack, Switch } from '@chakra-ui/react';
import { useBetaFeatures, useSharedState } from '@xmcloud/hooks';
import { CONTENT_TRANSFER } from '@xmcloud/hooks/context/useSharedState';
import { useFeature } from 'app/feature-flag/features';
import { CardBox, Text } from 'app/shared-components';

export const BetaFeaturesPage: React.FC = () => {
	const createProjectV3 = useFeature('CreateProjectV3');
	const contentTransfer = useFeature('ContentTransfer');

	const {
		createProjectV3BetaFeature,
		setCreateProjectV3BetaFeature,
		contentTransferExpFeat,
		setContentTransferExpFeature,
	} = useBetaFeatures();
	const { setState: setContentTransferEnabled } = useSharedState(
		CONTENT_TRANSFER,
		contentTransferExpFeat,
	);
	const noBetaFeatures = !createProjectV3 && !contentTransfer;

	if (noBetaFeatures)
		return (
			<Box
				width="full"
				alignItems="center"
				display="flex"
				justifyContent="center"
				pt="5%"
			>
				<Text fontWeight="semibold" text="No beta features found" />
			</Box>
		);

	return (
		<Stack spacing="4">
			{createProjectV3 && (
				<CardBox
					title="Create projects with CMs and EHs in different repositories or use the same repository"
					description="You can now streamline your project creation by leveraging a single repository, either from a template or using your own code. Additionally, you have the flexibility to create projects with environments spread across different repositories, utilizing the XMcloud template or your custom code."
				>
					<Switch
						isChecked={createProjectV3BetaFeature}
						onChange={(e) => {
							setCreateProjectV3BetaFeature(e.target.checked);
						}}
					/>
				</CardBox>
			)}
			{contentTransfer && (
				<CardBox
					title="Content Transfer"
					description="Transfer content between instances"
				>
					<Switch
						isChecked={contentTransferExpFeat}
						onChange={(e) => {
							setContentTransferExpFeature(e.target.checked);
							setContentTransferEnabled(e.target.checked);
						}}
					/>
				</CardBox>
			)}
		</Stack>
	);
};
