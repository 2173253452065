import { invariant } from '@epic-web/invariant';

const assertError = (error: unknown): Error | undefined => {
	if (error === null || error === undefined) return undefined;

	invariant(
		error instanceof Error || typeof error === 'string',
		'Expected error to be Error or string',
	);

	return error instanceof Error ? error : new Error(error);
};

export { assertError };
