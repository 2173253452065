import { useGetSourceControlAccessToken } from 'app/services/accessToken';
import { assertError } from '../shared/utils/error';
import { Provider } from '../shared/types/project';
import { useMemo } from 'react';
import { ESourceControl } from '@xmcloud/types';

interface TokenResult {
	token: string | undefined;
	provider: Provider | undefined;
	isLoading: boolean;
	error: Error | undefined;
}

export const useProviderToken = (
	integrationId: string,
	enabled: boolean,
): TokenResult => {
	const {
		data: tokenResponse,
		isLoading,
		error,
	} = useGetSourceControlAccessToken(integrationId, enabled);

	// Extract provider from token
	const provider: Provider | undefined = useMemo(() => {
		if (!tokenResponse?.data?.provider) return;

		return tokenResponse.data.provider === 1
			? ESourceControl.GitHub
			: tokenResponse.data.provider === 2
				? ESourceControl.ADO
				: '';
	}, [tokenResponse?.data?.provider]);

	return {
		token: tokenResponse?.data?.accessToken,
		provider,
		isLoading,
		error: assertError(error),
	};
};
