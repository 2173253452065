import { FC, useMemo } from 'react';
import { Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { IGetEnvironmentLogs } from '../../../models/environmentModel';
import { useGetEnvironmentLogs } from '../../../services/environments';
import { LoadingSkeleton, Table } from '../../../shared-components';
import { cmInstanceLogsColumns } from '../columns/logs';
import { pageSize } from '../../../../@xmcloud/utils/constants';
import { cmInstanceLog } from '../../../../@xmcloud/core/messages/en';
import { useGetEnvType } from '../hooks/useGetEnvType';

const { noLogsAvailable } = cmInstanceLog;

interface Props {
	category: string;
}

const headers = ['name', 'Last modified', 'Time', 'Size ', ''];

export const CMInstanceLogFilesTable: FC<Props> = ({ category }) => {
	const { projectId, environmentId, envType } = useGetEnvType();

	const { data, isLoading } = useGetEnvironmentLogs({
		environmentId: environmentId!,
		type: category,
	});

	const logs = data?.data || [];

	const columns: ColumnDef<IGetEnvironmentLogs, any>[] = useMemo(() => {
		return cmInstanceLogsColumns(projectId!, environmentId!, envType);
	}, [projectId, environmentId, envType]);

	if (isLoading) return <LoadingSkeleton amount={5} />;
	if (!logs.length)
		return (
			<Text variant="subtle" textAlign="center">
				{noLogsAvailable}
			</Text>
		);
	return (
		<Table
			{...{
				columns,
				tableData: logs,
				initialSort: [{ id: 'lastModified', desc: true }],
				dataTestId: 'environment-deployments-table',
				showPagination: logs.length > pageSize,
				headers,
			}}
		/>
	);
};
