import { Navigate, useLocation, useParams } from 'react-router-dom';
import { ReactNode } from 'react';
import { useCreateProjectV3 } from '../../../app/pages/create-project/old-components/useCreateProjectV3';
import { useGetEnvironment } from '../../../app/services/environments';
import {
	fetchLastSuccessfulLoginOrganizationId,
	ORGANIZATION,
} from 'app/auth/Auth0Storage';

/**
 * This component is used to protect the sites route for non EH projects only and redirect to 404 for EH project type
 * @param children
 * @return {JSX.Element}
 */
function ProtectedSitesRoute({ children }: { children: ReactNode }) {
	const { projectId, ...rest } = useParams<{
		projectId: string;
		// Used for the updated route for V3
		'envType-environments': string;
	}>();

	const { enableCreateProjectV3: isCreateProjectV3 } =
		useCreateProjectV3() || {};

	const envType = rest['envType-environments']?.split('-')[0];
	const type = isCreateProjectV3 ? envType : 'combined';

	// Redirect to 404 if type is undefined or EH
	if (!type || type === 'eh') {
		return <Navigate to="/404" />;
	}

	return <>{children}</>;
}

/**
 * This component is used to redirect to the correct environment type route for create project V3
 * if the environment type is not defined, it will get the type from the environment data, if the environment data is not available,
 * it will redirect to the environment page with the correct type in the URL path for create project V3 projects
 * @param children
 */
function EnvironmentRedirectRoute({ children }: { children: ReactNode }) {
	const { projectId, environmentId, ...rest } = useParams<{
		projectId: string;
		'envType-environments': string;
		environmentId: string;
	}>();
	const location = useLocation();
	const { enableCreateProjectV3: isCreateProjectV3 } = useCreateProjectV3();

	let envType = rest['envType-environments']?.split('-')[0];
	const envDepsAreMissing =
		!['eh', 'cm'].includes(envType || '') && isCreateProjectV3;

	const { data: environment } = useGetEnvironment({
		id: environmentId!,
		enabled: envDepsAreMissing,
	});
	const orgId = fetchLastSuccessfulLoginOrganizationId();

	// if envType is not defined or it's not eh or cm, get the type from the environment data
	if (envDepsAreMissing) {
		envType = environment?.data?.type;
	}

	if (isCreateProjectV3 && envType) {
		const environmentPath = location.pathname;

		const newEnvironmentPath = environmentPath.replace(
			`/projects/${projectId}/environments`,
			`/projects/${projectId}/${envType}-environments`,
		);

		return (
			<>
				<Navigate
					replace
					to={`${newEnvironmentPath}?${`${ORGANIZATION}=${orgId}`}`}
				/>
				{children}
			</>
		);
	}

	return <>{children}</>;
}

export { ProtectedSitesRoute, EnvironmentRedirectRoute };
