import { FC, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import {
	app,
	connections,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';

import {
	useComponentHealthy,
	useEnvironmentDetails,
	useProjectDetails,
	useValidateConnection,
} from '../../../../@xmcloud/hooks/';
import { getTabIndex } from '../../../../@xmcloud/utils/helpers';
import { useDeploymentCreateAndDeploy } from '../../../services/deploymentCreateDeploy';
import {
	HeaderStack,
	XMCSBreadCrumb,
	XMCSMenuButton,
	XMCSTabs,
} from '../../../shared-components';
import { environmentItems } from '../../../shared-components/breadcrumb/items';
import {
	commonEnvTabItems,
	EHEnvironementTabItems,
	environementTabItems as environmentTabItems,
} from '../../../shared-components/tab/items';
import DeleteEnvironment from '../components/DeleteEnvironment';
import EnvironmentMenuItems from '../components/EnvironmentMenuItems';
import LinkEnvToOtherBranch from '../components/LinkEnvToOtherBranch';
import { Main as EditEnvironment } from '../../create-edit-environment/Main';
import { useGetEnvType } from '../hooks/useGetEnvType';
import { EnvironmentPageLegacy } from './legacy-pages/EnvironmentPageLegacy';
import { EnvironmentTypesEnum } from '../../create-project/helpers';

const { errorMsg5 } = systemHealthStatus;
const { noSourceControl: noSourceControltxt } = connections;

export const EnvironmentPage: FC = () => {
	const { isLegacyEnvsRoute } = useGetEnvType();

	return isLegacyEnvsRoute ? (
		<EnvironmentPageLegacy />
	) : (
		<NewEnvironmentPage />
	);
};

const NewEnvironmentPage: FC = () => {
	const { projectId, environmentId, envType, isCmEnv } = useGetEnvType();

	const { pathname } = useLocation();
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [linkToAnotherBranchModal, setLinkToAnotherBranchModal] =
		useState(false);
	const [enableIntegrationValidation, setEnableIntegrationValidation] =
		useState(false);

	const {
		environment,
		isLoading: isEnvironmentLoading,
		environmentName,
		environmentType,
	} = useEnvironmentDetails(environmentId!);

	const { projectName, project, sourceControlIntegrationId } =
		useProjectDetails(projectId!);

	const tabItemsMap = {
		cm: commonEnvTabItems,
		eh: EHEnvironementTabItems,
	};
	const tabType = envType as EnvironmentTypesEnum;

	const tabItems = tabItemsMap[tabType] || environmentTabItems;
	const tabIndex = getTabIndex(pathname, tabType, false);

	const [deploymentCreateAndDeploy, isDeploymentLoading] =
		useDeploymentCreateAndDeploy();

	const {
		isValidIntegration,
		integrationIdValidationMsg,
		provider,
		isLoading: isValidationIntegrationLoading,
		isNotValidIntegration,
	} = useValidateConnection({
		sourceControlIntegrationId,
		enabled: !!sourceControlIntegrationId && enableIntegrationValidation,
	});
	const {
		isGithubProvider,
		isAdoProvider,
		isCurrentProviderHealthy,
		isLoading: isHealthStatusLoading,
	} = useComponentHealthy({ provider });

	const isLoading =
		isDeploymentLoading ||
		isEnvironmentLoading ||
		isValidationIntegrationLoading ||
		isHealthStatusLoading;

	const isProviderHealthy = isCurrentProviderHealthy && isValidIntegration;
	const integrationErrorMessage = isLoading
		? ''
		: !sourceControlIntegrationId
			? noSourceControltxt
			: integrationIdValidationMsg || errorMsg5;

	const breadCrumbItems = environmentItems(projectName, projectId!, tabType);

	return (
		<>
			<XMCSBreadCrumb items={breadCrumbItems} />
			<HeaderStack title={environmentName} mb={2}>
				<XMCSMenuButton
					text={app.options}
					{...{
						'data-testid': 'menu-button',
						isLoading,
						onClick: () => setEnableIntegrationValidation(true),
					}}
				>
					<EnvironmentMenuItems
						{...{
							setOpenDeleteModal,
							setOpenEditModal,
							environmentId: environmentId!,
							projectId: projectId!,
							environmentType,
							deploymentCreateAndDeploy,
							host: environment?.host,
							setLinkToAnotherBranchModal,
							environment,
							isProviderHealthy,
							isAdoProvider,
							isGithubProvider,
							integrationErrorMessage,
							enableIntegrationValidation,
							isNotValidIntegration,
						}}
					/>
				</XMCSMenuButton>
			</HeaderStack>
			<XMCSTabs
				tabIndex={tabIndex}
				tabItems={tabItems}
				state={{ projectName, environmentName }}
				args={[projectId!, environmentId!, envType!]}
			/>
			{openEditModal && (
				<EditEnvironment
					isOpen={openEditModal}
					onClose={() => setOpenEditModal(false)}
					isCreate={false}
					isCm={isCmEnv}
					environment={environment}
				/>
			)}
			{openDeleteModal && Boolean(environment) && (
				<DeleteEnvironment
					{...{
						openModal: openDeleteModal,
						setOpenModal: setOpenDeleteModal,
						environmentDetails: environment,
					}}
				/>
			)}

			{linkToAnotherBranchModal && (
				<LinkEnvToOtherBranch
					openModal={linkToAnotherBranchModal}
					setOpenModal={setLinkToAnotherBranchModal}
					environmentId={environmentId}
					project={project}
					repositoryBranch={environment?.repositoryBranch}
				/>
			)}
			<Outlet />
		</>
	);
};
