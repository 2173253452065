import useLocalStorage from 'use-local-storage';

//localStorage keys

const CONTENT_TRANSFER_EXPERIMENTAL_FEATURE =
	'ContentTransferExperimentalFeature';
const CREATE_PROJECT_V3_BETA_FEATURE = 'CreateProjectV3BetaFeature';

export function useBetaFeatures() {
	const [createProjectV3BetaFeature, setCreateProjectV3BetaFeature] =
		useLocalStorage<boolean>(CREATE_PROJECT_V3_BETA_FEATURE, false);
	const [contentTransferExpFeat, setContentTransferExpFeature] =
		useLocalStorage<boolean>(CONTENT_TRANSFER_EXPERIMENTAL_FEATURE, false);
	return {
		createProjectV3BetaFeature,
		setCreateProjectV3BetaFeature,
		contentTransferExpFeat,
		setContentTransferExpFeature,
	};
}
