import { useParams } from 'react-router-dom';
import { EnvironmentTypesEnum } from '../../create-project/helpers';

const { EH, CM } = EnvironmentTypesEnum;

export const useGetEnvType = () => {
	const { projectId, environmentId, ...rest } = useParams<{
		projectId: string;
		environmentId: string;
		// Used for the updated route for V3
		'envType-environments': string;
	}>();

	const envType = rest['envType-environments']?.split('-')[0];

	const isEhEnv = envType === EH;
	const isCmEnv = envType === CM;

	const isLegacyEnvsRoute = !(isEhEnv || isCmEnv);

	return {
		envType,
		isLegacyEnvsRoute,
		isEhEnv,
		isCmEnv,
		environmentId,
		projectId,
	};
};
