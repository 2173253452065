import { handlePush } from '../../../../@xmcloud/utils/helpers';
import CreateDeploymentForm from './CreateDeploymentForm';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { useDeploymentCreateAndDeploy } from '../../../services/deploymentCreateDeploy';

const {
	deploymentLogs: deploymentLogsPath,
	oldDeploymentLogs: oldDeploymentLogsPath,
} = goTo;

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
}

const CreateDeployment: React.FC<Props> = ({ openModal, setOpenModal }) => {
	const [deploymentCreateAndDeploy, isLoading] =
		useDeploymentCreateAndDeploy();
	const handleCreateDeployment = (values: any) => {
		const isNewEnvRoute = values.envType;
		deploymentCreateAndDeploy({
			environmentId: values.environmentId,
			onSuccess: (deploymentId: string) => {
				handlePush(
					isNewEnvRoute
						? deploymentLogsPath(
								values.project,
								values.environmentId,
								deploymentId,
								values.envType,
							)
						: oldDeploymentLogsPath(
								values.project,
								values.environmentId,
								deploymentId,
							),
				);
			},
		});
	};
	return (
		<CreateDeploymentForm
			{...{
				openModal,
				setOpenModal,
				onSubmit: handleCreateDeployment,
				isLoading,
			}}
		/>
	);
};

export default CreateDeployment;
