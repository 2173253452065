import {
	Button,
	ButtonGroup,
	Flex,
	Stack,
	Tooltip,
	Wrap,
} from '@chakra-ui/react';
import {
	EnvironmentTypesEnum,
	TFormikCommonProps,
} from '../../../pages/create-project/helpers';
import {
	connections,
	secondStep,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import { ESourceControl, SourceControlType } from '../../../../@xmcloud/types';
import {
	FormControl,
	SelectFormControl,
	XMCSButton,
	Text,
	HeadingSm,
	Icon,
} from '../../../shared-components';
import { useSetupProvider } from './useSetupProvider';
import { Provider } from 'app/features/project/shared/types/project';
import { mdiInformationOutline } from '@mdi/js';

const { github, ado, sourceControlProvider } = connections;
const { info1, addAzure, addGithub, azureAccount, githubAccount } = secondStep;
const { githubStatus, adoStatus } = systemHealthStatus;

const { GitHub, ADO } = ESourceControl;

interface SetupProviderAccountProps extends TFormikCommonProps {
	provider: SourceControlType | Provider;
	nameSpace?: EnvironmentTypesEnum | 'default';
	isIntegrationIdValidationValid: boolean;
	integrationValidationErrorMsg: string;
	isSourceControlIntegrationLoading: boolean;
	setValues: (values: any) => void;
	setTouched: any;
	setErrors: any;
	setFieldTouched: any;
	onChangeProvider?: (provider: SourceControlType | Provider) => void;
	onChangeConnection?: (sourceControlIntegrationId: string) => void;
	title?: string;
	description?: string;
	hasWarning?: boolean;
	isRequired: boolean;
}

export const SetupProviderAccount: React.FC<SetupProviderAccountProps> = ({
	provider,
	values,
	errors,
	touched,
	nameSpace = 'default',
	setValues,
	setTouched,
	setErrors,
	setFieldTouched,
	isIntegrationIdValidationValid,
	integrationValidationErrorMsg,
	isSourceControlIntegrationLoading,
	onChangeProvider,
	onChangeConnection,
	title = 'Repository',
	description = '',
	hasWarning = false,
	isRequired = true,
}) => {
	const {
		providerNameSpace,
		sourceControlIntegrationIdNameSpace,
		isGithub,
		isAdo,
		isADOHealthy,
		isGithubHealthy,
		isFetchingIntegrationLoading,
		isFetchingIntegrateState,
		integrationsOptions,
		handleProviderChange,
		handleConnectionChange,
		handleAddIntegration,
	} = useSetupProvider({
		nameSpace,
		values,
		setValues,
		setTouched,
		setErrors,
		onChangeProvider,
		onChangeConnection,
	});

	return (
		<Stack gap="4">
			<HeadingSm text={title} />

			<Flex alignItems="flex-start">
				{hasWarning && (
					<Icon
						path={mdiInformationOutline}
						color="orange"
						mt={0.5}
						mr={2}
					/>
				)}
				<Text text={description} color="chakra-subtle-text" />
			</Flex>
			<FormControl
				isRequired={isRequired}
				label={sourceControlProvider}
				error={errors[providerNameSpace] || ''}
			>
				<ButtonGroup
					variant="toggle"
					size="sm"
					layerStyle="toggleGroup"
				>
					<Tooltip label={githubStatus} isDisabled={isGithubHealthy}>
						<Button
							onClick={() => handleProviderChange(GitHub)}
							data-testid="github-provider-btn"
							isActive={isGithub}
							isDisabled={!isGithubHealthy}
						>
							{github}
						</Button>
					</Tooltip>
					<Tooltip label={adoStatus} isDisabled={isADOHealthy}>
						<Button
							onClick={() => handleProviderChange(ADO)}
							data-testid="ado-provider-btn"
							isActive={isAdo}
							isDisabled={!isADOHealthy}
						>
							{ado}
						</Button>
					</Tooltip>
				</ButtonGroup>
			</FormControl>
			{!!provider && (
				<Stack gap="2">
					<SelectFormControl
						isInvalid={Boolean(
							(errors[sourceControlIntegrationIdNameSpace] ||
								!isIntegrationIdValidationValid) &&
								touched[sourceControlIntegrationIdNameSpace],
						)}
						isLoading={
							isFetchingIntegrationLoading ||
							isSourceControlIntegrationLoading
						}
						options={integrationsOptions}
						onChange={handleConnectionChange}
						label={isGithub ? githubAccount : azureAccount}
						name={sourceControlIntegrationIdNameSpace}
						currentValue={
							values[sourceControlIntegrationIdNameSpace]
						}
						error={
							errors[sourceControlIntegrationIdNameSpace] ||
							integrationValidationErrorMsg
						}
						pt={2}
						onFocus={() =>
							setFieldTouched(
								sourceControlIntegrationIdNameSpace,
								true,
							)
						}
						isRequired={true}
						textContent={info1}
					/>
					<Wrap>
						<XMCSButton
							text={isGithub ? addGithub : addAzure}
							isLoading={isFetchingIntegrateState}
							variant="link"
							onClick={handleAddIntegration}
						/>
					</Wrap>
				</Stack>
			)}
		</Stack>
	);
};
