import { mainPaths } from './@xmcloud/core/routes/paths';
import { PublicStatusPage } from './app/pages';
import { MainContainer } from './app/main/MainContainer';
import { Route, Routes } from 'react-router-dom';
import AppProviders from './AppProviders';
import { Error401Page } from './app/pages/error-401/Main';

const App: React.FC = () => (
	<AppProviders>
		<Routes>
			<Route path={mainPaths.error401} element={<Error401Page />} />
			<Route path={mainPaths.status} element={<PublicStatusPage />} />
			<Route path="*" element={<MainContainer />} />
		</Routes>
	</AppProviders>
);

export default App;
