import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Stack,
} from '@chakra-ui/react';
import {
	cmInstanceLog,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import { useGetEnvironmentLogTypes } from '../../../services/environments';
import {
	AlertStatus,
	LoadingSkeleton,
	RenderPlaceholder,
	Text14B700,
} from '../../../shared-components';
import { CMInstanceLogFilesTable } from '../..';
import { useComponentHealthy } from '../../../../@xmcloud/hooks';
import { categoryFilter } from './cmInstanceLogsUtils';
import { useGetEnvType } from '../hooks/useGetEnvType';
import { EnvironmentTypesEnum } from '../../create-project/helpers';

const { unavailableLogging } = systemHealthStatus;
const { category: categorytxt } = cmInstanceLog;

export const CMInstancePage: React.FC = () => {
	const { environmentId, envType } = useGetEnvType();
	const { isMonitoringHealthy, isLoading: isHealthStatusLoading } =
		useComponentHealthy({});

	const { data, isLoading: isEnvironmentLogsTypesLoading } =
		useGetEnvironmentLogTypes({
			environmentId: environmentId!,
		});

	const rawCategories = data?.data || [];

	const categories = categoryFilter(
		rawCategories,
		envType as EnvironmentTypesEnum,
	);

	const isLoading = isEnvironmentLogsTypesLoading || isHealthStatusLoading;

	if (isLoading) return <LoadingSkeleton height={14} amount={8} />;

	return (
		<Stack>
			{!isMonitoringHealthy && (
				<AlertStatus
					description={unavailableLogging}
					status="warning"
					data-testid="monitoring-warning-alert"
				/>
			)}
			{!categories.length ? (
				<RenderPlaceholder item={categorytxt.toLowerCase()} />
			) : (
				<Accordion
					data-testid="cminstancelogs-accordion"
					mt="8"
					mb={['20', '8', '8', '8']}
					allowToggle
					defaultIndex={categories.length === 1 ? [0] : []}
				>
					{categories.map((category) => (
						<AccordionItem key={category}>
							{({ isExpanded }) => (
								<>
									<AccordionButton minH="12">
										<Box
											as="span"
											flex="1"
											textAlign="left"
										>
											<Text14B700
												text={category.split('.')[0]}
											/>
										</Box>
										<AccordionIcon />
									</AccordionButton>
									<AccordionPanel mt={['5', '1', '1', '1']}>
										{isExpanded && (
											<CMInstanceLogFilesTable
												category={category}
											/>
										)}
									</AccordionPanel>
								</>
							)}
						</AccordionItem>
					))}
				</Accordion>
			)}
		</Stack>
	);
};
