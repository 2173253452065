import { forIn, get, pick } from 'lodash';
import {
	fetchLastSuccessfulLoginOrganizationId,
	ORGANIZATION,
} from '../../app/auth/Auth0Storage';
import { history } from '../core/routes/history/history';
import { IEnvironmentDetails } from '../../app/models/environmentModel';
import { ESourceControl } from '../types';
import _ from 'lodash';

type PathsIndex = {
	deployments: number;
	sites?: number;
	details: number;
	'log-types': number;
	logs: number;
	variables: number;
	'developer-settings': number;
};

export function getAdjustedPathsIndex(type: string): Record<string, number> {
	const pathsIndex: PathsIndex = {
		details: 0,
		deployments: 1,
		sites: 2,
		'log-types': 3,
		logs: 3,
		variables: 4,
		'developer-settings': 5,
	};
	// FIXME: Cannot use ProjectTypeEnum.EH due to ResponseStatusEnum is not yet defined while importing the ProjectTypeEnum.EH, see in tests that we have to mock it.
	if (type === 'eh') {
		delete pathsIndex.sites;
		pathsIndex.details = 0;
		pathsIndex.deployments = 1;
		pathsIndex['log-types'] = 2;
		pathsIndex.logs = 2;
		pathsIndex.variables = 3;
		pathsIndex['developer-settings'] = 4;
	}

	return pathsIndex;
}

export function getAdjustedPathsIndexLegacy(): Record<string, number> {
	const pathsIndex: PathsIndex = {
		deployments: 0,
		sites: 1,
		details: 2,
		'log-types': 3,
		logs: 3,
		variables: 4,
		'developer-settings': 5,
	};
	return pathsIndex;
}

export const getTabIndex = (
	pathname: string,
	type: string,
	isLegacy: boolean = true,
): number => {
	const pathsIndex = isLegacy
		? getAdjustedPathsIndexLegacy()
		: getAdjustedPathsIndex(type);

	let lastPath = pathname.split('/').slice(-1)[0] as keyof typeof pathsIndex;
	return pathsIndex[lastPath] || 0;
};

export const handleHTMLTagLink = (text: string) => {
	const includeTag = text && text.startsWith('<a');

	const tagState = text && text.match(/<a.*?href="(.*?)".*?>(.*?)<\/a>/);
	if (!(includeTag && tagState)) return { text, link: '' };
	return {
		text: tagState[2],
		link: tagState[1],
	};
};

export const handleTo = (url: string): string => {
	const orgId = fetchLastSuccessfulLoginOrganizationId();
	const finalUrl = appendQueryStringToUrl(url, ORGANIZATION, orgId);

	return finalUrl;
};

export const appendQueryStringToUrl = (
	url: string,
	key: string,
	value: string | number | undefined,
): string => {
	const connector = url.indexOf('?') > -1 ? '&' : '?';
	const finalUrl = `${url}${connector}${key}=${value}`;

	return finalUrl;
};
export const handlePush = (url: string) => history.push(handleTo(url));

export function downloadLogs(logs: string, filename: string) {
	const element = document.createElement('a');
	const file = new Blob([logs], { type: 'text/plain' });
	element.href = URL.createObjectURL(file);
	element.download = `${filename}`;
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
}

export function formatBytes(bytes: number, decimals = 2) {
	if (!+bytes) return '0 Bytes';
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function pickObjectsDifferences<T>(obj: T, comparator: T): any {
	const keysToPick: string[] = [];
	forIn(obj, (value, key) => {
		if (value !== get(comparator, key)) {
			keysToPick.push(key);
		}
	});

	return keysToPick.length ? pick(obj, keysToPick) : null;
}

const providers = { github: 1, ado: 2, default: 0 };
const providersInNumber = {
	1: ESourceControl.GitHub.toLowerCase(),
	2: ESourceControl.ADO.toLowerCase(),
	0: 'default',
};
export function convertProviderEnum(
	provider: string | number | undefined,
	returnType: string,
) {
	if (returnType === 'number' && provider && typeof provider === 'string') {
		// @ts-ignore
		return providers[provider.toLowerCase()] || providers['default'];
	}

	if (returnType === 'string' && provider && typeof provider === 'number') {
		// @ts-ignore
		return providersInNumber[provider] || providersInNumber[0];
	}

	return typeof provider === 'string' ? provider.toLowerCase() : provider;
}

export const onDisabled = (
	isValid: boolean,
	values: IEnvironmentDetails,
	initialValues: IEnvironmentDetails | undefined,
	validationIsLoading = false,
): boolean => {
	if (!isValid) return true;
	if (validationIsLoading) return true;
	const differences = pickObjectsDifferences(values, initialValues);
	if (!differences) return true;
	return false;
};

export const isPhoneDevice = /Android|iPhone|iPad|Windows Phone/i.test(
	navigator.userAgent,
);

//removes empty properties from object such as empty strings, null, undefined
export const cleanEmptyProperties = (obj: any): Object =>
	Object.entries(obj)
		.map(([k, v]) => [
			k,
			v && typeof v === 'object' ? cleanEmptyProperties(v) : v,
		])
		.reduce(
			//@ts-ignore
			(a, [k, v]) => (v == null || v === '' ? a : ((a[k] = v), a)),
			{},
		);

export type ResponseStatusTypes = 'error' | 'idle' | 'loading' | 'success';
export enum ResponseStatusEnum {
	ERROR = 'error',
	IDLE = 'idle',
	LOADING = 'loading',
	SUCCESS = 'success',
}

export function difference(object: any, base: any) {
	function changes(object: any, base: any) {
		return _.transform(object, function (result: any, value, key) {
			if (!_.isEqual(value, base[key])) {
				result[key] =
					_.isObject(value) && _.isObject(base[key])
						? changes(value, base[key])
						: value;
			}
		});
	}
	return changes(object, base);
}

export function downloadLogsWithUrl(url: string, filename: string) {
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', filename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}
