import { ESourceControl, TenantType } from '../../../@xmcloud/types';
import { IGetEnvironmentResponseV2 } from '../../models/environmentModel';
import { EnvironmentTypesEnum } from '../create-project/helpers';

const { ADO } = ESourceControl;
const { CM, EH } = EnvironmentTypesEnum;
const { Production, NonProduction } = TenantType;

export const repositoryFields = {
	repository: 'repository',
	repositoryId: 'repositoryId',
	sourceControlIntegrationId: 'sourceControlIntegrationId',
	repositoryRelativePath: 'repositoryRelativePath',
	repositoryBranch: 'repositoryBranch',
	sourceControlIntegrationName: 'sourceControlIntegrationName',
	environmentId: 'environmentId',
	deployOnCommit: 'deployOnCommit',
};

export const requiredEnvFields = {
	environmentName: 'environmentName',
	provider: 'provider',
	tenantType: 'tenantType',
	adoProjectName: 'adoProjectName',
	adoOrganization: 'adoOrganization',
	cmEnvironmentId: 'cmEnvironmentId',
	...repositoryFields,
};

const getDefaultRelativePath = (c: boolean) =>
	c ? './authoring' : './headapps/nextjs-starter';

export const handleUpdatedValues = ({
	env,
	project,
	providerName,
	isCreate,
	isCm = true,
	isBackupEnv,
}: {
	env: IGetEnvironmentResponseV2;
	providerName: string;
	isCreate: boolean;
	isCm: boolean;
	project: any;
	isBackupEnv: boolean;
}) => {
	const isAdo = providerName === ADO;
	const repository =
		env?.repositoryDetails?.repository || project?.repository || '';
	const [adoOrganization = '', adoProjectName = '', adoRepository = ''] =
		repository.split('/');

	const backupEnvRelativePath = getDefaultRelativePath(isBackupEnv && isCm);
	const defaultRelativePath = getDefaultRelativePath(isCm);

	const repositoryRelativePath = isBackupEnv
		? backupEnvRelativePath
		: !!env?.repositoryDetails?.repositoryRelativePath
			? env?.repositoryDetails?.repositoryRelativePath
			: defaultRelativePath;

	return {
		environmentName: isCreate ? '' : env?.name || '',
		cmEnvironmentId:
			env?.editingHostEnvironmentDetails?.cmEnvironmentId || '',
		repository,
		repositoryId:
			env?.repositoryDetails?.repositoryId || project?.repositoryId || '',
		repositoryBranch: env?.repositoryBranch || '',
		deployOnCommit: env?.deployOnCommit || false,
		tenantType: env?.tenantType === 'prod' ? Production : NonProduction,
		sourceControlIntegrationId:
			env?.repositoryDetails?.sourceControlIntegrationId ||
			project?.sourceControlIntegrationId ||
			'',
		sourceControlIntegrationName:
			env?.repositoryDetails?.sourceControlIntegrationName ||
			project?.sourceControlIntegrationName ||
			'',
		repositoryOwner:
			env?.repositoryDetails?.sourceControlIntegrationName ||
			project?.sourceControlIntegrationName ||
			'',
		repositoryRelativePath,
		environmentType: env?.type || isCm ? CM : EH,
		provider: providerName,
		...(isAdo && {
			adoOrganization,
			adoProjectName,
			repository: adoRepository,
		}),
	};
};
