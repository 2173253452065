import { useEffect } from 'react';
import { projects } from '../../../@xmcloud/core/messages/en';
import { useEditProject } from '../../../@xmcloud/hooks';
import {
	EnumInNumberSourceControl,
	ESourceControl,
} from '../../../@xmcloud/types';
import { IGetEnvironmentResponse } from '../../models/environmentModel';
import { IGetProjectResponseV2 } from '../../models/projectModel';
import { IRepositoryIntegrationDetails } from '../../models/repositoriesModel';
import { InputFormControl } from '../../shared-components';
import {
	ACCOUNT,
	ADO_ORGANIZATION,
	ADO_PROJECT_NAME,
	ENVIRONMENTS,
	HAS_ACCOUNT,
	NAME,
	PROVIDER,
	PROVIDER_NAME,
	REPOSITORY,
	REPOSITORY_ID,
	SOURCE_CONTROL_INTEGRATION_ID,
} from '../create-project/helpers';
import { ProviderText } from './SelectProvider';

const { nameTitle } = projects;
const { GitHub } = EnumInNumberSourceControl;

export const ProjectName: React.FC<{
	projectDetail: IGetProjectResponseV2;
	integrationData: IRepositoryIntegrationDetails | undefined;
	environmentsData: IGetEnvironmentResponse[];
}> = ({ projectDetail, integrationData, environmentsData }) => {
	const {
		setFieldTouched,
		errors,
		touched,
		values,
		handleChange,
		editValues,
		setValues,
		setPrevValues,
		setEditValues,
	} = useEditProject();
	const { editName } = editValues;
	const { hasAccount } = values;

	const enableNameField = hasAccount && !editName;

	useEffect(() => {
		const projectName = projectDetail.name;
		const sourceControlIntegrationId =
			projectDetail.sourceControlIntegrationId || '';
		const repositoryName = projectDetail.repository || '';
		const repositoryId = projectDetail.repositoryId || '';
		const account = integrationData?.account || '';
		const providerInNumber = (integrationData?.provider ||
			0) as EnumInNumberSourceControl;
		const providerName = ProviderText[
			providerInNumber as EnumInNumberSourceControl
		] as ESourceControl;
		const isGithubProvider = providerInNumber === GitHub;
		const [adoOrganization, adoProjectName] = repositoryName.split('/');
		const adoOrganizationName = isGithubProvider ? '' : adoOrganization;
		const adoProjectNameValue = isGithubProvider ? '' : adoProjectName;

		const environments = environmentsData.map((e) => ({
			deployOnCommit: !!e.deployOnCommit,
			repositoryBranch: e.repositoryBranch || '',
			id: e.id,
			name: e.name,
		}));

		setValues((prev) => ({
			...prev,
			[NAME]: projectName || '',
			[SOURCE_CONTROL_INTEGRATION_ID]: sourceControlIntegrationId,
			[REPOSITORY]: repositoryName,
			[ADO_ORGANIZATION]: adoOrganizationName,
			[ADO_PROJECT_NAME]: adoProjectNameValue,
			[HAS_ACCOUNT]: repositoryName ? true : false,
			[ACCOUNT]: account,
			[PROVIDER]: providerInNumber,
			[PROVIDER_NAME]: providerName,
			[ENVIRONMENTS]: environments,
			[REPOSITORY_ID]: repositoryId,
		}));
		setPrevValues((prev) => ({
			...prev,
			[NAME]: projectName || '',
			[SOURCE_CONTROL_INTEGRATION_ID]: sourceControlIntegrationId,
			[REPOSITORY]: repositoryName,
			[ADO_ORGANIZATION]: adoOrganizationName,
			[ADO_PROJECT_NAME]: adoProjectNameValue,
			[HAS_ACCOUNT]: repositoryName ? true : false,
			[ACCOUNT]: account,
			[PROVIDER]: providerInNumber,
			[PROVIDER_NAME]: providerName,
			[ENVIRONMENTS]: environments,
			[REPOSITORY_ID]: repositoryId,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<InputFormControl
			{...{
				isInvalid: Boolean(errors.name && touched.name),
				label: nameTitle,
				name: NAME,
				value: values.name,
				onChange: handleChange,
				error: errors.name,
				showAsPlaceHolder: enableNameField,
				isEditable: true,
				onFocus: () => setFieldTouched(NAME, true),
				onEditClick: () => {
					setEditValues((prev) => ({
						...prev,
						editName: true,
					}));
				},
			}}
		/>
	);
};
