import { editCreateEnv } from '@xmcloud/core/messages/en';
import { ADOProject } from '../types';
import { SelectFormControl } from 'app/shared-components';
import React from 'react';

interface ProjectSelectProps {
	projects: ADOProject[];
	value: string;
	onChange: (value: string) => void;
	onFocus: () => void;
	isLoading: boolean;
	disabled: boolean;
	error: string;
	isRequired?: boolean;
	isTouched: boolean;
}

const { adoProject } = editCreateEnv;

export const ProjectSelect = React.memo(
	({
		projects,
		value,
		onChange,
		onFocus,
		isLoading,
		disabled,
		error,
		isRequired,
		isTouched,
	}: ProjectSelectProps) => (
		<SelectFormControl
			label={adoProject}
			name={'adoProjectName'}
			currentValue={value}
			onChange={(option) => onChange(option.value)}
			onFocus={() => onFocus}
			options={projects.map((project) => ({
				value: project.name,
				label: project.name,
			}))}
			isLoading={isLoading}
			isDisabled={disabled}
			error={error}
			isRequired={isRequired}
			pt={0}
			isInvalid={Boolean(error) && isTouched}
		/>
	),
);
