import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useGetGitHubPaginatedBranches } from '../../services/githubApi';
import {
	connectionNameSpaces,
	EnvironmentTypesEnum,
	repositoryBranchNamespaces,
	repositoryNameSpaces,
	TConnectionName,
	TRepository,
	TRepositoryBranch,
} from '../../pages/create-project/helpers';
import { FormikValues } from '../../pages/create-project/hooks/CreateProjectContext';
import { SelectFormControl } from '../../shared-components';
import { debounce } from 'lodash';
import { repository } from '../../../@xmcloud/core/messages/en';

const { branch: branchtxt } = repository;

export const SelectGithubBranch: React.FC<{
	values: Partial<FormikValues>;
	token: string;
	nameSpace?: EnvironmentTypesEnum | 'default';
	errors: any;
	setFieldValue: (field: string, value: any) => void;
	setFieldTouched: any;
	touched: any;
}> = ({
	token,
	setFieldValue,
	nameSpace = 'default',
	values,
	errors,
	touched,
	setFieldTouched,
}) => {
	const inputQuery = useRef('');
	const repositoryNamespace = repositoryNameSpaces[nameSpace] as TRepository;
	const connectionNameSpace = connectionNameSpaces[
		nameSpace
	] as TConnectionName;
	const repositoryBranchNamespace = repositoryBranchNamespaces[
		nameSpace
	] as TRepositoryBranch;
	const owner = values[connectionNameSpace] || '';
	const repo = values[repositoryNamespace] || '';

	const { data, isLoading, hasMore, fetchNextPage, isFetching } =
		useGetGitHubPaginatedBranches({
			token,
			owner,
			repo,
			onSuccess,
		});

	function onSuccess(lastPage: any) {
		const query = inputQuery.current;

		if (query === '' || !hasMore || !lastPage.length) return;

		const hasRepo = lastPage.some((r: any) => r.name.includes(query));

		if (!hasRepo) {
			fetchNextPage();
		}
	}

	const branches = useMemo(() => {
		let branches =
			data?.map((r: any) => ({
				label: r.name,
				value: r.name,
			})) || [];
		return branches;
	}, [data]);

	const currentBranch = useMemo(() => {
		if (!branches.length) return '';

		const masterOrMainBranch = branches.find(
			(b: any) => b.value === 'main' || b.value === 'master',
		);
		return Boolean(masterOrMainBranch)
			? masterOrMainBranch?.value
			: branches[0].value;
	}, [branches]);

	useEffect(() => {
		if (!values[repositoryBranchNamespace] && currentBranch) {
			setFieldValue(repositoryBranchNamespace, currentBranch);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentBranch]);

	const handleFetchNextPage = useCallback(() => {
		if (hasMore && !isFetching) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasMore, isFetching]);

	const onInputChange = useCallback(
		(e: string) => {
			if (inputQuery) {
				inputQuery.current = e;
			}

			if (e === '' || !hasMore || !branches?.length || isFetching) return;

			const hasRepo = branches.some((r: any) => r.label.includes(e));

			if (!hasRepo) {
				handleFetchNextPage();
			}
		},
		[branches, handleFetchNextPage, hasMore, inputQuery, isFetching],
	);

	const debouncedChangeHandler = useMemo(
		() => debounce(onInputChange, 300),
		[onInputChange],
	);

	useEffect(() => {
		return () => {
			debouncedChangeHandler.cancel();
		};
	}, [debouncedChangeHandler]);

	return (
		<SelectFormControl
			{...{
				error: errors[repositoryBranchNamespace],
				isInvalid: Boolean(
					errors[repositoryBranchNamespace] &&
						touched[repositoryBranchNamespace],
				),
				isLoading: isLoading || isFetching,
				options: branches,
				onChange: (e: any) => {
					setFieldValue(repositoryBranchNamespace, e.value);
				},
				label: branchtxt,
				name: repositoryBranchNamespace,
				currentValue: values[repositoryBranchNamespace],
				pt: 0,
				onInputChange: debouncedChangeHandler,
				onMenuScrollToBottom: () => {
					handleFetchNextPage();
				},
				isRequired: true,
				onFocus: () => setFieldTouched(repositoryBranchNamespace, true),
			}}
		/>
	);
};
