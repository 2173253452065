import { Text } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { app, cmInstanceLog } from '../../../../@xmcloud/core/messages/en';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import {
	getReadableDate,
	getReadableTime,
} from '../../../../@xmcloud/utils/dateUtils';
import { formatBytes, handleTo } from '../../../../@xmcloud/utils/helpers';
import { IGetEnvironmentLogs } from '../../../models/environmentModel';
import { Link } from '../../../shared-components';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<IGetEnvironmentLogs>();

const { size, lastModified, time } = cmInstanceLog;
const { cmInstanceLogs: cmInstanceLogsPath } = goTo;

export const cmInstanceLogsColumns = (
	projectId: string,
	environmentId: string,
	envType?: string,
) => {
	const environmentType = envType === 'environments' ? '' : envType;
	return [
		columnHelper.accessor((row) => row.name, {
			id: 'name',
			header: () => t(app.name),
			cell: ({
				row: {
					original: { size, name },
				},
			}) =>
				size === 0 ? (
					<Text noOfLines={2}>{name}</Text>
				) : (
					<Link
						text={name}
						to={handleTo(
							cmInstanceLogsPath(
								projectId,
								environmentId,
								name,
								size,
								environmentType,
							),
						)}
						whiteSpace="wrap"
						noOfLines={1}
						maxW={['xs', '100%', '100%', '100%']}
					/>
				),
		}),
		columnHelper.accessor((row) => row.lastModified, {
			id: 'lastModified',
			header: () => lastModified,
			cell: (info) => getReadableDate(info.getValue()),
		}),
		columnHelper.accessor((row) => row.lastModified, {
			id: time,
			header: () => time,
			cell: (info) => getReadableTime(info.getValue()),
			enableSorting: false,
		}),
		columnHelper.accessor((row) => row.size, {
			id: 'size',
			header: () => size,
			cell: (info) => formatBytes(info.getValue()),
		}),
		columnHelper.display({
			id: 'actions',
			cell: (info) => '',
			maxSize: 0,
		}),
	];
};
