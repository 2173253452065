import { MenuItem, Tooltip } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { FC, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { app, projects } from '../../../@xmcloud/core/messages/en';
import {
	CircularLoading,
	HeaderStack,
	XMCSBreadCrumb,
	XMCSMenuButton,
	XMCSTabs,
} from '../../shared-components';
import { goTo, projectPaths } from '../../../@xmcloud/core/routes/paths';
import { useGetProject } from '../../services/projects';
import DeleteProject from './components/DeleteProject';
import { projectPageItems } from '../../shared-components/breadcrumb/items';
import {
	projectTabItems,
	projectTabItemsLegacy,
} from '../../shared-components/tab/items';
import { handlePush } from '../../../@xmcloud/utils/helpers';
import { useCreateProjectV3 } from '../create-project/old-components/useCreateProjectV3';

const { canNotBeDeleted, edit, delete: enDelete } = projects;
const {
	projectDetails: projectDetailsPath,
	ehEnvironments: ehEnvironmentsPath,
	cmEnvironments: cmEnvironmentsPath,
	environments: environmentsPathLegacy,
} = projectPaths;

const { options: optionstxt } = app;

export function getTabIndex(isNewEnvsRoute: boolean, pathname: string) {
	if (isNewEnvsRoute) {
		if (pathname.includes(cmEnvironmentsPath)) return 0;
		if (pathname.includes(ehEnvironmentsPath)) return 1;
		if (pathname.includes(projectDetailsPath)) return 2;
	} else {
		if (pathname.includes(projectDetailsPath)) return 1;
		if (pathname.includes(environmentsPathLegacy)) return 0;
	}
	return 0;
}

export const ProjectPage: FC = () => {
	const { projectId } = useParams<{ projectId: string }>();
	const { pathname } = useLocation();
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const { data, isLoading } = useGetProject({ id: projectId as string });
	const { enableCreateProjectV3 } = useCreateProjectV3();

	const isNewEnvsRoute =
		!!data?.data?.environments[0]?.type ||
		(enableCreateProjectV3 && !data?.data?.environments.length);

	const tabItems = isNewEnvsRoute ? projectTabItems : projectTabItemsLegacy;

	const tabIndex = getTabIndex(isNewEnvsRoute, pathname);

	const projectName = data?.data.name;

	const environmentsLength = data?.data?.environments?.length;

	if (isLoading) return <CircularLoading />;

	return (
		<>
			<XMCSBreadCrumb items={projectPageItems} />
			<HeaderStack
				title={projectName}
				mb={2}
				tagText="Beta"
				hasTag={isNewEnvsRoute}
			>
				<XMCSMenuButton
					text={optionstxt}
					{...{
						disabled: !data?.data,
						'data-testid': 'menu-button',
					}}
				>
					<MenuItem
						onClick={() => handlePush(goTo.editProject(projectId!))}
						data-testid="menu-edit-item"
					>
						{t(edit)}
					</MenuItem>

					<MenuItem
						onClick={() => setOpenDeleteModal(true)}
						data-testid="menu-delete-item"
						isDisabled={Boolean(environmentsLength)}
					>
						<Tooltip
							label={t(canNotBeDeleted)}
							isDisabled={!environmentsLength}
							shouldWrapChildren
						>
							{t(enDelete)}
						</Tooltip>
					</MenuItem>
				</XMCSMenuButton>
			</HeaderStack>
			<XMCSTabs
				tabIndex={tabIndex}
				tabItems={tabItems}
				state={{ projectName }}
				args={[projectId as string]}
			/>
			{openDeleteModal && (
				<DeleteProject
					openModal={openDeleteModal}
					setOpenModal={setOpenDeleteModal}
					projectDetails={{
						id: projectId as string,
						name: projectName!,
					}}
				/>
			)}
			<Outlet />
		</>
	);
};
