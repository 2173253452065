import { useEffect, useMemo } from 'react';
import { ADO_BRANCH_PREFIX } from '../../../@xmcloud/utils/constants';
import { useGetADOBranches } from '../../services/adoApi';
import {
	adoOrganizationNamespaces,
	adoProjectNamespaces,
	EnvironmentTypesEnum,
	repositoryBranchNamespaces,
	repositoryNameSpaces,
	TFormikCommonProps,
} from '../../pages/create-project/helpers';
import { SelectFormControl } from '../../shared-components';
import { repository } from '../../../@xmcloud/core/messages/en';

const { branch: branchtxt } = repository;

export const SelectADOBranch: React.FC<
	{
		token: string;
		nameSpace?: EnvironmentTypesEnum | 'default';
		setFieldValue: any;
		setFieldTouched: any;
		isDataLoading?: boolean;
	} & TFormikCommonProps
> = ({
	token,
	nameSpace = 'default',
	values,
	errors,
	touched,
	setFieldValue,
	isDataLoading = false,
	setFieldTouched,
}) => {
	const adoOrganizationNamespace = adoOrganizationNamespaces[nameSpace];
	const adoProjectNamespace = adoProjectNamespaces[nameSpace];
	const repositoryNamespace = repositoryNameSpaces[nameSpace];
	const repositoryBranchNamespace = repositoryBranchNamespaces[nameSpace];

	const repositoryValue = values[repositoryNamespace] || '';
	const adoAdoOrganizationValue = values[adoOrganizationNamespace] || '';
	const adoProjectNamevalue = values[adoProjectNamespace] || '';

	const { data, isFetching } = useGetADOBranches(
		token,
		adoAdoOrganizationValue,
		adoProjectNamevalue,
		repositoryValue,
	);

	const branches = useMemo(() => {
		let branches =
			data?.data.value.map((r: any) => ({
				label: r.name.replace(ADO_BRANCH_PREFIX, ''),
				value: r.name.replace(ADO_BRANCH_PREFIX, ''),
			})) || [];
		return branches;
	}, [data]);

	const currentBranch = useMemo(() => {
		if (!branches.length) return '';

		const masterOrMainBranch = branches.find(
			(b: any) => b.value === 'main' || b.value === 'master',
		);
		return Boolean(masterOrMainBranch)
			? masterOrMainBranch?.value
			: branches[0].value;
	}, [branches]);

	useEffect(() => {
		if (!values[repositoryBranchNamespace] && currentBranch) {
			setFieldValue(repositoryBranchNamespace, currentBranch);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentBranch]);

	const isLoading = isFetching || isDataLoading;

	return (
		<SelectFormControl
			{...{
				error: errors[repositoryBranchNamespace],
				isInvalid: Boolean(
					errors[repositoryBranchNamespace] &&
						touched[repositoryBranchNamespace],
				),
				isLoading: isLoading,
				options: branches,
				onChange: (e: any) => {
					setFieldValue(repositoryBranchNamespace, e.value);
				},
				label: branchtxt,
				name: repositoryBranchNamespace,
				currentValue: values[repositoryBranchNamespace],
				pt: 0,
				isRequired: true,
				onFocus: () => setFieldTouched(repositoryBranchNamespace, true),
			}}
		/>
	);
};
