import { ModalBody, useToast, VStack } from '@chakra-ui/react';
import { SetupProviderAccount } from 'app/features/common-env-fields/SetupProviderAccount/SetupProviderAccount';
import { Alert, InputFormControl } from 'app/shared-components';
import { useFormik } from 'formik';
import { useProjectMutations } from 'app/features/project/hooks/useProject';
import { EditProjectFormValues } from 'app/features/project/shared/types/form';
import { ADOSelector } from 'app/features/project/integrations/ado/components/ADOSelector';
import { GitHubSelector } from 'app/features/project/integrations/github/components/GitHubSelector';
import { useProjectIntegration } from '../../hooks';

import { getRepositoryInfo, parseADORepository } from './helpers';
import {
	handleSubmitError,
	createSubmitHandlers,
	createConnectionHandler,
	createProviderChangeHandler,
	createSwitchBackHandler,
} from './handlers';
import { Provider } from '../../shared/types/project';
import { FormActions } from './FormActions';
import { editProject, projects } from '@xmcloud/core/messages/en';
import { handlePush } from '@xmcloud/utils/helpers';
import { goTo } from '@xmcloud/core/routes/paths';
import { t } from '@transifex/native';
import { REQUIRED_FIELDS } from '../../shared/constants/formFields';
import { useFormState } from './helpers/formState';
import { ProviderChangeAlert } from './Alerts';
import { createEditProjectSchema } from '../../shared/validation/editProjectSchema';
import { isSelectionEnabled } from './helpers';
import { useEffect, useState } from 'react';
import { hasSourceControlChanges } from './helpers/hasSourceControlChanges';
import { useIntegrationValidation } from '../../hooks/useIntegrationValidation';
import { ESourceControl, SourceControlType } from '@xmcloud/types';
import config from 'app/config/config';
import { useQueryClient } from 'react-query';

const { repoChangeConfirmation } = editProject;
const { connectionInfo, projectDetailsEdit } = projects;
const { queryKey } = config.projects.project;
/**
 * EditProjectForm component handles the editing of project details including name and repository settings.
 * It manages the form state for both GitHub and Azure DevOps (ADO) repositories and handles provider changes.
 *
 * Features:
 * - Project name editing
 * - Repository provider switching (GitHub/ADO)
 * - Repository selection and validation
 * - Integration status monitoring
 * - Form validation and error handling
 *
 * @param {Object} props - Component props
 * @param {string} props.projectId - The ID of the project being edited
 * @param {() => void} props.onClose - Callback function to close the edit form
 */
export const EditProjectForm = ({
	projectId,
	onClose,
}: {
	projectId: string;
	onClose: () => void;
}) => {
	const queryClient = useQueryClient();
	const { project, repository, integration, isLoading, refetchRepository } =
		useProjectIntegration(projectId);
	const mutations = useProjectMutations(projectId);

	const { adoOrganization, adoProjectName, adoRepository } =
		parseADORepository(project?.repository!!);
	const submitHandlers = createSubmitHandlers({
		projectId,
		mutations,
	});

	const toast = useToast();
	const noDefaultRepository =
		!project?.repository || !project?.sourceControlIntegrationId;

	const [validationSchema, setValidationSchema] = useState(() =>
		createEditProjectSchema(noDefaultRepository),
	);

	useEffect(() => {
		setValidationSchema(createEditProjectSchema(noDefaultRepository));
	}, [noDefaultRepository]);

	const formik = useFormik<EditProjectFormValues>({
		initialValues: {
			name: project?.name ?? '',
			provider: noDefaultRepository
				? ''
				: repository?.provider === 2
					? ESourceControl.ADO
					: ESourceControl.GitHub,
			repository: project?.repository ?? '',
			repositoryId: project?.repositoryId ?? '',
			sourceControlIntegrationId:
				project?.sourceControlIntegrationId ?? '',
			repositoryRelativePath:
				project?.repositoryRelativePath ?? undefined,
			adoOrganization,
			adoProjectName,
			adoRepository,
		},
		validationSchema,
		enableReinitialize: true,
		validateOnBlur: false,
		onSubmit: async (values) => {
			const nameChanged = values.name !== project?.name;
			const repoChanged = hasSourceControlChanges({
				formik,
				project: project!,
			});

			delete values.repositoryRelativePath;

			try {
				if (nameChanged && repoChanged) {
					await submitHandlers.handleBothChanges(values);
				} else if (nameChanged) {
					await submitHandlers.handleNameChange(values);
				} else if (repoChanged) {
					await submitHandlers.handleRepoChange(values);
				}

				toast({
					status: 'success',
					description: t(projectDetailsEdit),
				});
				queryClient.invalidateQueries(queryKey(projectId));
				handlePush(goTo.projectDetails(projectId));
			} catch (error: unknown) {
				handleSubmitError(error, {
					toast,
					setFieldValue: formik.setFieldValue,
				});
			}
		},
	});

	const selectionEnabled = isSelectionEnabled({
		isIntegrationValid: integration.isValid,
		noDefaultRepository,
	});

	const {
		isRepositoryChanged,
		hasAccount,
		isChangingProvider,
		isSubmitDisabled,
	} = useFormState({
		formik,
		project,
		repository,
		integration,
		mutations,
		selectionEnabled,
		noDefaultRepository,
	});

	const handleSwitchBack = createSwitchBackHandler(
		formik.setValues,
		project,
		repository,
	);

	const selectedIntegrationId = formik.values.sourceControlIntegrationId;

	const {
		integrationError,
		isValidToken,
		integrationToken,
		hasActiveValidationError,
	} = useIntegrationValidation(
		selectedIntegrationId,
		formik.values.provider as SourceControlType,
	);

	useEffect(() => {
		if (hasActiveValidationError(selectedIntegrationId)) {
			formik.setFieldError(
				'sourceControlIntegrationId',
				integrationError.integrationValid.errorMsg,
			);
			formik.setFieldTouched('sourceControlIntegrationId', true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [integrationError]);

	const handleProviderChange = async (provider: SourceControlType) => {
		const initialProvider =
			repository?.provider === 2
				? ESourceControl.ADO
				: ESourceControl.GitHub;
		if (provider === initialProvider) {
			handleSwitchBack();
			return;
		}

		await createProviderChangeHandler(formik.setValues)(
			provider as Provider,
		);
		formik.setTouched(
			{
				name: true,
			},
			false,
		);
	};

	const handleConnectionChange = createConnectionHandler({
		formik,
		refetchRepository,
	});

	return (
		<form
			data-testid="edit-project-form"
			onSubmit={formik.handleSubmit}
			noValidate={true}
		>
			<ModalBody>
				<VStack spacing={4} align="stretch">
					<InputFormControl
						label="Project Name"
						name="name"
						value={formik.values.name}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						isInvalid={Boolean(
							formik.touched.name && formik.errors.name,
						)}
						error={
							formik.touched.name ? formik.errors.name : undefined
						}
						isRequired
					/>
					<SetupProviderAccount
						provider={formik.values.provider}
						nameSpace="default"
						values={formik.values}
						errors={formik.errors}
						touched={formik.touched}
						setValues={formik.setValues}
						setTouched={formik.setTouched}
						setErrors={formik.setErrors}
						setFieldTouched={formik.setFieldTouched}
						isSourceControlIntegrationLoading={isLoading}
						isIntegrationIdValidationValid={selectionEnabled}
						integrationValidationErrorMsg={integration.errorMsg}
						onChangeProvider={handleProviderChange}
						onChangeConnection={handleConnectionChange}
						isRequired={!noDefaultRepository}
						{...getRepositoryInfo(
							project?.repository ?? '',
							project?.sourceControlIntegrationId ?? '',
						)}
					/>
					{formik.values.provider === ESourceControl.ADO && (
						<ADOSelector
							integrationId={
								formik.values.sourceControlIntegrationId
							}
							selectedOrg={formik.values.adoOrganization}
							selectedProject={formik.values.adoProjectName}
							selectedRepo={formik.values.adoRepository}
							onSelect={(org, project, repo) => {
								formik.setValues((prev) => ({
									...prev,
									repository: `${org}/${project}/${repo.name}`,
									repositoryId: repo.id,
									adoOrganization: org,
									adoProjectName: project,
									adoRepository: repo.name,
								}));

								formik.setTouched(
									{
										...formik.touched,
										...REQUIRED_FIELDS,
									},
									false,
								);
							}}
							onFocus={() =>
								formik.setFieldTouched('adoOrganization', true)
							}
							error={formik.errors.adoOrganization ?? ''}
							isIntegrationValid={selectionEnabled}
							isValidProviderToken={isValidToken}
							isADOTouched={{
								adoOrganization:
									!!formik.touched.adoOrganization,
								adoProjectName: !!formik.touched.adoProjectName,
								adoRepository: !!formik.touched.adoRepository,
							}}
							token={integrationToken!}
						/>
					)}
					{formik.values.provider === ESourceControl.GitHub && (
						<GitHubSelector
							integrationId={
								formik.values.sourceControlIntegrationId
							}
							onSelect={(repoName, repo) => {
								formik.setValues((prev) => ({
									...prev,
									repository: repoName,
									repositoryId: repo.id,
								}));
							}}
							onFocus={() =>
								formik.setFieldTouched('repository', true)
							}
							selectedRepo={formik.values.repository}
							error={formik.errors.repository ?? ''}
							isTouched={!!formik.touched.repository}
							isRequired
							isIntegrationValid={selectionEnabled}
							isValidProviderToken={isValidToken}
							token={integrationToken!}
						/>
					)}
					{integration.isValid && isChangingProvider && (
						<ProviderChangeAlert
							connectionInfo={connectionInfo}
							onSwitchBack={handleSwitchBack}
							repository={repository}
						/>
					)}
					{integration.isValid &&
						isRepositoryChanged &&
						hasAccount && (
							<Alert
								status="warning"
								description={t(repoChangeConfirmation)}
								minW="100%"
								mt={4}
							/>
						)}

					{hasActiveValidationError(selectedIntegrationId) && (
						<Alert
							status="error"
							description={t(
								integrationError.integrationValid.errorMsg,
							)}
							minW="100%"
							mt={4}
						/>
					)}
				</VStack>
			</ModalBody>
			<FormActions
				isSubmitting={formik.isSubmitting}
				isDisabled={isSubmitDisabled}
				isRepositoryChanged={integration.isValid && isRepositoryChanged}
				hasAccount={hasAccount}
				hasError={!!formik.errors.repository}
				onClose={onClose}
			/>
		</form>
	);
};
