import { FC, useEffect, useState } from 'react';
import { mainPaths } from '../../../@xmcloud/core/routes/paths';
import { handlePush } from '../../../@xmcloud/utils/helpers';
import {
	CreateTransferProvider,
	useBetaFeatures,
} from '../../../@xmcloud/hooks';
import { MainModal } from './components';
import {
	CONTENT_TRANSFER,
	useSharedState,
} from '../../../@xmcloud/hooks/context/useSharedState';
import { Outlet } from 'react-router-dom';

export const WizardCreateTransfer: FC = () => {
	const [isOpen, setIsOpen] = useState(true);

	function onClose() {
		setIsOpen(false);
		handlePush(mainPaths.contenttransfer);
	}
	const { contentTransferExpFeat } = useBetaFeatures();
	const { state: contentTransferEnabled } = useSharedState(
		CONTENT_TRANSFER,
		contentTransferExpFeat,
	);

	useEffect(() => {
		if (!contentTransferEnabled) {
			//It blocks to reach current page if user try to add the url manually, if feature flag is disabled
			handlePush(mainPaths.noMatch);
		}
	}, [contentTransferEnabled]);

	return (
		<CreateTransferProvider>
			<MainModal {...{ isOpen, onClose }}>
				<Outlet />
			</MainModal>
		</CreateTransferProvider>
	);
};
