import { useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { FormikValues } from '../../../pages/create-project/hooks/CreateProjectContext';
import {
	useGetRepositoryIntegrations,
	useRepositoryIntegrateState,
} from '../../../services/repositories';
import { useComponentHealthy } from '../../../../@xmcloud/hooks';
import {
	ACCOUNT_TYPE,
	EnvironmentTypesEnum,
	adoOrganizationNamespaces,
	adoProjectNamespaces,
	connectionNameSpaces,
	providerNameSpaces,
	repositoryBranchNamespaces,
	repositoryIdNamespaces,
	repositoryNameSpaces,
	sourceControlIntegrationIdNameSpaces,
} from '../../../pages/create-project/helpers';
import {
	NEW_SOURCE_CONTROL_ACCOUNT,
	adoIntegrationUrl,
	githubIntegrationUrl,
} from '../../../../@xmcloud/utils/constants';
import {
	ESourceControl,
	EnumInNumberSourceControl,
	SourceControlType,
} from '../../../../@xmcloud/types';
import config from '../../../config/config';
import { Provider } from 'app/features/project/shared/types/project';

const { GitHub, ADO } = ESourceControl;
const { GitHub: GitHubInNumber, ADO: AdoInNumber } = EnumInNumberSourceControl;
const { validate_integration_V2 } = config.repository;

interface UseSetupProviderProps {
	nameSpace?: EnvironmentTypesEnum | 'default';
	values: Partial<FormikValues>;
	setValues: (values: any) => void;
	setTouched: (touched: any) => void;
	setErrors: (errors: any) => void;
	onChangeProvider?: (provider: SourceControlType | Provider) => void;
	onChangeConnection?: (sourceControlIntegrationId: string) => void;
}

export const useSetupProvider = ({
	nameSpace = 'default',
	values,
	setValues,
	setTouched,
	setErrors,
	onChangeProvider,
	onChangeConnection,
}: UseSetupProviderProps) => {
	const queryClient = useQueryClient();

	const providerNameSpace = providerNameSpaces[nameSpace];
	const connectionNameSpace = connectionNameSpaces[nameSpace];
	const sourceControlIntegrationIdNameSpace =
		sourceControlIntegrationIdNameSpaces[nameSpace];
	const repositoryNamespace = repositoryNameSpaces[nameSpace];
	const repositoryIdNamespace = repositoryIdNamespaces[nameSpace];
	const repositoryBranchNamespace = repositoryBranchNamespaces[nameSpace];
	const adoOrganizationNamespace = adoOrganizationNamespaces[nameSpace];
	const adoProjectNamespace = adoProjectNamespaces[nameSpace];

	const currentProvider = values[providerNameSpace];
	const isGithub = currentProvider === GitHub;
	const isAdo = currentProvider === ADO;

	const {
		isADOHealthy,
		isGithubHealthy,
		isLoading: isHealthCheckLoading,
	} = useComponentHealthy({
		provider: isGithub ? GitHubInNumber : isAdo ? AdoInNumber : 0,
	});

	const {
		data: repoIntegrationData,
		isLoading: isFetchingIntegrationLoading,
	} = useGetRepositoryIntegrations();

	const {
		refetch: fetchIntegrateState,
		isFetching: isFetchingIntegrateState,
	} = useRepositoryIntegrateState({ enabled: false });

	const repositoryIntegrations = useMemo(
		() =>
			repoIntegrationData?.data.filter(
				(i) => i.provider === currentProvider,
			) || [],
		[repoIntegrationData, currentProvider],
	);

	const integrationsOptions = repositoryIntegrations.map(
		({ account, id }) => ({
			label: account,
			value: id,
		}),
	);

	useEffect(() => {
		const channel = new BroadcastChannel(NEW_SOURCE_CONTROL_ACCOUNT);

		channel.addEventListener('message', (e) => {
			if (e.origin !== window.location.origin) return;
			queryClient.invalidateQueries(
				validate_integration_V2.queryKey(e.data.id),
			);
			setTouched({});
			setErrors({});
			setValues((prev: FormikValues) => ({
				...prev,
				[connectionNameSpace]: e.data.account,
				[sourceControlIntegrationIdNameSpace]: e.data.id,
				[providerNameSpace]: e.data.provider,
				[ACCOUNT_TYPE]: e.data.accountType,
			}));
		});

		return () => {
			channel.close();
		};
	}, [
		setValues,
		setTouched,
		setErrors,
		queryClient,
		connectionNameSpace,
		sourceControlIntegrationIdNameSpace,
		providerNameSpace,
	]);

	useEffect(() => {
		if (!isGithubHealthy && isGithub) {
			setValues((prev: FormikValues) => ({
				...prev,
				[providerNameSpace]: '',
			}));
		}
	}, [
		isHealthCheckLoading,
		isGithubHealthy,
		isGithub,
		setValues,
		providerNameSpace,
	]);

	const handleProviderChange = (provider: SourceControlType) => {
		if (onChangeProvider) {
			onChangeProvider(provider);
			return;
		}
		setValues((prev: FormikValues) => ({
			...prev,
			[repositoryNamespace]: '',
			[repositoryIdNamespace]: '',
			[sourceControlIntegrationIdNameSpace]: '',
			[connectionNameSpace]: '',
			[repositoryBranchNamespace]: '',
			[providerNameSpace]: provider,
		}));
		setTouched({});
	};

	const handleConnectionChange = (e: any) => {
		if (onChangeConnection) {
			onChangeConnection(e.value);
			return;
		}
		const integration = repositoryIntegrations?.find(
			(i: any) => i.id === e.value,
		);
		if (!integration?.provider) return;
		setValues((prev: FormikValues) => ({
			...prev,
			[sourceControlIntegrationIdNameSpace]: e.value || '',
			[connectionNameSpace]: integration?.account,
			[providerNameSpace]: integration.provider as SourceControlType,
			[ACCOUNT_TYPE]: integration?.accountType || 0,
			[repositoryNamespace]: '',
			[repositoryIdNamespace]: '',
			[repositoryBranchNamespace]: '',
			[adoOrganizationNamespace]: '',
			[adoProjectNamespace]: '',
		}));
	};

	const handleAddIntegration = async () => {
		if (values[providerNameSpace]) {
			const { data } = await fetchIntegrateState();
			const state = data?.data ?? '';
			window.open(
				isGithub
					? githubIntegrationUrl(state)
					: isAdo
						? adoIntegrationUrl(state)
						: '#',
			);
		}
	};

	return {
		providerNameSpace,
		sourceControlIntegrationIdNameSpace,
		isGithub,
		isAdo,
		isADOHealthy,
		isGithubHealthy,
		isFetchingIntegrationLoading,
		isFetchingIntegrateState,
		integrationsOptions,
		handleProviderChange,
		handleConnectionChange,
		handleAddIntegration,
	};
};
