import { EditProjectFormValues } from 'app/features/project/shared/types/form';
import { IGetProjectResponseV2 } from 'app/models/projectModel';
import { FormikProps } from 'formik';

interface RepoChangedParams {
	project: IGetProjectResponseV2;
	formik: FormikProps<EditProjectFormValues>;
}

export const hasSourceControlChanges = ({
	project,
	formik,
}: RepoChangedParams): boolean => {
	return (
		project.sourceControlIntegrationId !==
			formik.values.sourceControlIntegrationId ||
		project.repository !== formik.values.repository
	);
};
