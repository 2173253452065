import { useProject } from './useProject';
import { useRepository, useValidateRepository } from './useRepository';
import { assertError } from '../shared/utils/error';
import {
	IGetProjectResponseV2,
	// IValidateResponse,
} from 'app/models/projectModel';
import { IRepositoryIntegrationDetails } from 'app/models/repositoriesModel';

interface ProjectIntegrationResult {
	project: IGetProjectResponseV2 | null;
	repository: IRepositoryIntegrationDetails | null;
	integration: {
		isValid: boolean;
		errorMsg: string;
		isValidating: boolean;
	};
	isLoading: boolean;
	isLoadingProject: boolean;
	isLoadingRepo: boolean;
	isValidating: boolean;
	error: Error | null;
	refetchProject: () => void;
	// refetchValidationWithId: (
	// 	sourceControlIntegrationId: string,
	// ) => Promise<IValidateResponse>;
	refetchRepository: (
		sourceControlIntegrationId: string,
	) => Promise<IRepositoryIntegrationDetails>;
}

/**
 * Custom hook that manages the integration between a project and its associated repository.
 * It handles project data fetching, repository validation, and repository details retrieval.
 *
 * The hook maintains state for:
 * - Project details
 * - Repository integration details
 * - Integration validation status
 * - Loading and error states
 *
 * @param projectId - The unique identifier of the project to fetch integration details for
 * @returns {ProjectIntegrationResult} An object containing:
 *  - project: The project data or null
 *  - repository: The repository integration details or null
 *  - integration: Object containing validation status and error messages
 *  - isLoading: Boolean indicating if data is being loaded
 *  - isValidating: Boolean indicating if repository validation is in progress
 *  - error: Any error that occurred during the process
 *  - refetchProject: Function to reload project data
 *  - refetchValidationWithId: Function to revalidate repository with a new integration ID
 *  - refetchRepository: Function to reload repository details
 */
export const useProjectIntegration = (
	projectId: string,
): ProjectIntegrationResult => {
	const {
		data: project,
		isLoading: isLoadingProject,
		error: projectError,
		refetch: refetchProject,
	} = useProject(projectId);

	const integrationId = project?.data?.sourceControlIntegrationId || '';
	const shouldValidate = Boolean(integrationId);

	const {
		data: validationData,
		isLoading: isValidating,
		error: validationError,
	} = useValidateRepository(integrationId, shouldValidate);

	const {
		data: repository,
		isLoading: isLoadingRepo,
		error: repoError,
		refetchRepoWithId,
	} = useRepository({
		id: integrationId,
		enabled: !!integrationId && !!validationData?.data?.isValid,
	});

	const errors = [projectError, validationError, repoError]
		.filter(Boolean)
		.map(assertError);

	return {
		project: project?.data ?? null,
		repository: repository?.data ?? null,
		integration: {
			isValid: validationData?.data?.isValid ?? false,
			errorMsg: validationData?.data?.message ?? '',
			isValidating,
		},
		isLoading: isLoadingProject || isLoadingRepo,
		isLoadingProject,
		isLoadingRepo,
		isValidating,
		error: errors[0] || null,
		refetchProject,
		refetchRepository: refetchRepoWithId,
	};
};
