import { useQueryClient } from 'react-query';
import config from '../../app/config/config';
import { IGetProjectResponseV2 } from '../../app/models/projectModel';
import { useGetProject } from '../../app/services/projects';
import { QueryResponse } from '../types';
import { useCreateProjectV3 } from '../../app/pages/create-project/old-components/useCreateProjectV3';
const {
	projects: { project: projectConfig },
} = config;

export function useProjectDetails(projectId: string) {
	const queryClient = useQueryClient();
	const { enableCreateProjectV3 } = useCreateProjectV3();

	const projectQueryData = queryClient.getQueryData(
		projectConfig.queryKey(projectId),
	) as QueryResponse<IGetProjectResponseV2>;

	const {
		data: projectData,
		isLoading,
		isFetching,
		refetch,
	} = useGetProject({
		id: projectId,
		enabled: !Boolean(projectQueryData?.data),
	});
	const project = projectQueryData?.data || projectData?.data;
	const projectName = project?.name || '';
	const sourceControlIntegrationName =
		project?.sourceControlIntegrationName || '';
	const repository = project?.repository || '';
	const repositoryLinkedAt = project?.repositoryLinkedAt || '';
	const sourceControlIntegrationId =
		project?.sourceControlIntegrationId || '';
	const region = project?.region || '';
	const isSplittedProject =
		!!project?.environments[0]?.type ||
		(!project?.environments.length && enableCreateProjectV3);

	return {
		projectName,
		project,
		isLoading,
		isFetching,
		refetch,
		sourceControlIntegrationName,
		sourceControlIntegrationId,
		repositoryLinkedAt,
		repository,
		region,
		isSplittedProject,
	};
}
