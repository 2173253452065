import { FormikProps } from 'formik';
import { IRepositoryIntegrationDetails } from 'app/models/repositoriesModel';
import { EditProjectFormValues } from 'app/features/project/shared/types/form';
import { IValidateResponse } from 'app/models/projectModel';

interface ConnectionHandlerParams {
	formik: FormikProps<EditProjectFormValues>;
	refetchRepository?: (id: string) => Promise<IRepositoryIntegrationDetails>;
	refetchValidationWithId?: (id: string) => Promise<IValidateResponse>;
}

/**
 * Creates a handler for managing source control integration connection changes.
 *
 * @param params - Configuration object containing form and fetch utilities
 * @param params.formik - Formik instance containing form state and helpers
 * @returns An async function that handles source control connection changes
 */
export const createConnectionHandler = ({
	formik,
}: ConnectionHandlerParams) => {
	return async (sourceControlIntegrationId: string) => {
		// Set new integration ID
		formik.setFieldValue(
			'sourceControlIntegrationId',
			sourceControlIntegrationId,
		);

		// Clear previous state
		formik.setValues((prev) => ({
			...prev,
			sourceControlIntegrationId,
			repository: '',
			repositoryId: '',
			adoOrganization: '',
			adoProjectName: '',
			adoRepository: '',
		}));

		formik.setTouched({
			sourceControlIntegrationId: true,
			provider: true,
		});
	};
};
