import {
	useCreateRepositoryMutation,
	useRepositoryHookSubscriptionMutation,
} from '../../../services/repositories';
import { useCreateProject } from './CreateProjectContext';
import { ResponseStatusEnum } from '../../../../@xmcloud/utils/helpers';
import { useEffect, useState } from 'react';
import {
	CM_ADO_ORGANIZATION,
	CM_ADO_PROJECT_NAME,
	CM_REPO_BRANCH,
	CM_REPOSITORY,
	CM_REPOSITORY_ID,
	EnvironmentTypesEnum,
	getProcessStatus,
} from '../helpers';
import { useCreateProjectMutationV2 } from '../../../services/projects';
import {
	useGetADORepository,
	useUpdateADORepository,
} from '../../../services/adoApi';
import { ADO_BRANCH_PREFIX } from '../../../../@xmcloud/utils/constants';
import { useGetGithubPublicRepository } from '../../../services/githubApi';
import { useEnvironmentCreation } from '.';

const { ERROR, IDLE, LOADING, SUCCESS } = ResponseStatusEnum;
const { CM } = EnvironmentTypesEnum;

export const useAdoProjectCreation = ({
	token,
	onCmEnvironmentCreationSuccess,
}: {
	token: string;
	onCmEnvironmentCreationSuccess?: (
		cmEnvironmentId: string,
		projectId: string,
	) => void;
}) => {
	const { values, isByoc, setValues, isSingleEnvironmentCreation } =
		useCreateProject();
	const [projectID, setprojectID] = useState('');
	const [adoRepositoyStatus, setAdoRepositoyStatus] = useState(IDLE);

	//get XM CLOUD template default branch
	const { data: githubRepositoryData, isLoading: isLoadingGithubRepository } =
		useGetGithubPublicRepository(values.templateOwner, values.template);
	const templateDefaultBranch =
		githubRepositoryData?.data.default_branch || '';

	useEffect(() => {
		if (templateDefaultBranch && !isByoc) {
			setValues((prev) => ({
				...prev,
				[CM_REPO_BRANCH]: templateDefaultBranch,
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templateDefaultBranch]);

	const {
		mutate: createRepo,
		isLoading: isCreateRepoLoading,
		status: createRepoStatus,
	} = useCreateRepositoryMutation();

	const {
		mutate: repoHookSubscription,
		isLoading: isLoadingHookSubscription,
		status: hookSubscriptionStatus,
	} = useRepositoryHookSubscriptionMutation();

	function onGetAdoSuccess(defaultBranch: string) {
		if (defaultBranch && values.cmRepository) {
			setAdoRepositoyStatus(SUCCESS);
			onSecondProcess();
		}
	}

	useGetADORepository({
		token,
		organization: values.cmAdoOrganization,
		project: values.cmAdoProjectName,
		repo: values.cmRepository,
		onSuccess: onGetAdoSuccess,
		_enabled: createRepoStatus === 'success',
	});

	const {
		mutate: updateADORepo,
		isLoading: isLoadingUpdateADORepo,
		status: updateADORepoStatus,
	} = useUpdateADORepository(
		token,
		values.cmAdoOrganization,
		values.cmAdoProjectName,
		values.cmRepositoryId,
	);

	const {
		mutate: createProject,
		isLoading: isLoadingCreateProject,
		status: createProjectStatus,
	} = useCreateProjectMutationV2();

	const {
		isLoading: isLoadingCreateEnvironment,
		onEnvironmentLevelProcess,
		environmentCreationStatus,
		deploymentCreationStatus,
		environmentVariableCreationStatus,
		shouldRetry: shouldRetryFifthProcess,
	} = useEnvironmentCreation({
		shouldGoto: isSingleEnvironmentCreation,
		environmentType: CM,
		onCmEnvironmentCreationSuccess,
	});

	const repoPath = `${values.cmAdoOrganization}/${values.cmAdoProjectName}/${values.cmRepository}`;
	const shouldRetryFirstProcess = createRepoStatus === ERROR;
	const shouldRetrySecondProcess = hookSubscriptionStatus === ERROR;
	const shouldRetryThirdProcess = updateADORepoStatus === ERROR;
	const shouldRetryFourthProcess = createProjectStatus === ERROR;

	function onFirstProcess() {
		const payload = {
			integrationId: values.cmSourceControlIntegrationId,
			owner: values.cmSourceControlIntegrationName,
			repositoryName: repoPath,
			templateRepository: values.template,
			templateOwner: values.templateOwner,
		};
		createRepo(payload, {
			onSuccess: (data) => {
				const fullName = data.data.fullName;
				const splitedFullName = fullName.split('/');
				const adoOrganization = splitedFullName[0];
				const adoProjectName = splitedFullName[1];
				const repositoryName = splitedFullName[2];
				const repositoryId = data.data.id || '';
				const owner = data.data.owner;
				setAdoRepositoyStatus(LOADING);
				setValues((prev) => ({
					...prev,
					[CM_ADO_ORGANIZATION]: adoOrganization,
					[CM_ADO_PROJECT_NAME]: adoProjectName,
					[CM_REPOSITORY]: repositoryName,
					[CM_REPOSITORY_ID]: repositoryId,
					repositoryOwner: owner,
				}));
			},
		});
	}

	function onSecondProcess() {
		const repoHookSubsPayload = {
			repositoryName: repoPath,
			integrationId: values.cmSourceControlIntegrationId,
		};
		repoHookSubscription(repoHookSubsPayload, {
			onSuccess: () => {
				onThirdProcess();
			},
		});
	}
	function onThirdProcess() {
		const defaultBranch = `${ADO_BRANCH_PREFIX}${templateDefaultBranch}`;

		if (isByoc) {
			onFourthProcess();
			return;
		}

		updateADORepo(
			{
				defaultBranch,
			},
			{
				onSuccess: () => {
					onFourthProcess();
				},
			},
		);
	}

	function onFourthProcess() {
		const projectPayload = {
			name: values.name,
			repository: repoPath,
			sourceControlIntegrationId: values.cmSourceControlIntegrationId,
			repositoryId: `${values.cmRepositoryId}`,
			sourceControlIntegrationName: values.cmSourceControlIntegrationName,
			repositoryRelativePath: './authoring',
		};
		createProject(
			{ project: projectPayload },
			{
				onSuccess: (data) => {
					const currentProjectId = data.data.id;
					setprojectID(currentProjectId);
					setValues((prev) => ({
						...prev,
						projectId: currentProjectId,
					}));
					onFifthProcess(currentProjectId);
				},
			},
		);
	}

	const onFifthProcess = (projectId: string) => {
		onEnvironmentLevelProcess(projectId, values.cmEnvironmentId);
	};

	function onHandleCreationProcess() {
		if (shouldRetryFirstProcess) {
			onFirstProcess();
		} else if (shouldRetrySecondProcess) {
			onSecondProcess();
		} else if (shouldRetryThirdProcess) {
			onThirdProcess();
		} else if (shouldRetryFourthProcess) {
			onFourthProcess();
		} else if (shouldRetryFifthProcess) {
			onFifthProcess(projectID);
		} else {
			if (isByoc) {
				onThirdProcess();
				return;
			}
			onFirstProcess();
		}
	}

	const repositoryCreationStatus = getProcessStatus(
		createRepoStatus,
		adoRepositoyStatus,
		hookSubscriptionStatus,
	);

	const projectCreationStatus = getProcessStatus(
		createProjectStatus,
		isByoc ? SUCCESS : updateADORepoStatus,
	);

	const isRepositoryFetchingLoading = adoRepositoyStatus === LOADING;

	const isLoading =
		isCreateRepoLoading ||
		isRepositoryFetchingLoading ||
		isLoadingCreateProject ||
		isLoadingCreateEnvironment ||
		isLoadingHookSubscription ||
		isLoadingUpdateADORepo ||
		isLoadingGithubRepository;

	const shouldRetry =
		shouldRetryFirstProcess ||
		shouldRetrySecondProcess ||
		shouldRetryThirdProcess ||
		shouldRetryFourthProcess ||
		shouldRetryFifthProcess;

	return {
		isLoading,
		onHandleCreationProcess,
		repositoryCreationStatus,
		projectCreationStatus,
		environmentCreationStatus,
		deploymentCreationStatus,
		environmentVariableCreationStatus,
		shouldRetry,
	};
};
