import { useADOSelection } from '../hooks/useADOHooks';
import { OrganizationSelect } from './OrganizationSelect';
import { ProjectSelect } from './ProjectSelect';
import { RepositorySelect } from './RepositorySelect';

interface ADOSelectorProps {
	integrationId: string;
	selectedOrg: string;
	selectedProject?: string;
	selectedRepo?: string;
	onSelect: (org: string, project: string, repo: any) => void;
	onFocus?: (value: string) => void;
	error: string;
	isIntegrationValid: boolean;
	isValidProviderToken: boolean;
	isADOTouched: {
		adoOrganization: boolean;
		adoProjectName: boolean;
		adoRepository: boolean;
	};
	token: string;
}

export const ADOSelector = ({
	integrationId,
	selectedOrg,
	selectedProject,
	selectedRepo,
	onSelect,
	onFocus,
	error,
	isIntegrationValid,
	isValidProviderToken,
	isADOTouched,
	token,
}: ADOSelectorProps) => {
	const { data, isLoading } = useADOSelection({
		integrationId,
		selectedOrg,
		selectedProject,
		enabled: isIntegrationValid,
		isValidProviderToken,
		token,
	});

	return (
		<>
			<OrganizationSelect
				organizations={data.organizations}
				value={selectedOrg ?? ''}
				onChange={(org) => onSelect(org, '', { id: '', name: '' })}
				onFocus={() => onFocus && onFocus('adoOrganization')}
				isLoading={isLoading.organizations}
				error={error}
				isRequired
				isDisabled={!isIntegrationValid}
				isTouched={isADOTouched.adoOrganization}
			/>

			<ProjectSelect
				projects={data.projects}
				value={selectedProject ?? ''}
				onChange={(project) =>
					onSelect(selectedOrg!, project, { id: '', name: '' })
				}
				onFocus={() => onFocus && onFocus('adoProjectName')}
				isLoading={isLoading.projects}
				disabled={!selectedOrg || !isIntegrationValid}
				error={error}
				isRequired
				isTouched={isADOTouched.adoProjectName}
			/>

			<RepositorySelect
				repositories={data.repositories}
				value={selectedRepo ?? ''}
				onChange={(repoName) => {
					const repo = data.repositories.find(
						(r) => r.name === repoName,
					);
					if (repo) {
						onSelect(selectedOrg!, selectedProject!, repo);
					}
				}}
				onFocus={() => onFocus && onFocus('adoRepository')}
				isLoading={isLoading.repositories}
				disabled={!selectedProject || !isIntegrationValid}
				error={error}
				isRequired
				isTouched={isADOTouched.adoRepository}
			/>
		</>
	);
};
