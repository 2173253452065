import { Skeleton, Stack, Tag, Wrap } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { HeadingXl } from '../text';

interface Props {
	children: ReactNode;
	title: string | undefined;
	mt?: number;
	mb?: number;
	color?: string;
	maxW?: string;
	hasTag?: boolean;
	tagText?: string;
}

export const HeaderStack: FC<Props> = ({
	title,
	children,
	mt = 0,
	mb = 6,
	maxW = 'lg',
	hasTag = false,
	tagText,
}) => (
	<Stack direction="row" justify="space-between" mt={mt} mb={mb}>
		{!!title ? (
			<Wrap align="center">
				<HeadingXl as="h1" maxW={maxW} noOfLines={1} text={title} />
				{hasTag && <Tag colorScheme="info">{tagText}</Tag>}
			</Wrap>
		) : (
			<Stack direction="column" w={36}>
				<Skeleton h="7" />
			</Stack>
		)}
		{children}
	</Stack>
);
