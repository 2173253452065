import { FC, useEffect, useState } from 'react';
import { transfers } from '../../../@xmcloud/core/messages/en';
import {
	Footer,
	HeaderStack,
	ToggleStack,
	XMCSButton,
} from '../../shared-components';
import TransfersTable, { TransferQuery } from './components/TransfersTable';
import { Text, Tab, TabList } from '@chakra-ui/react';
import { useBetaFeatures, useSharedState } from '../../../@xmcloud/hooks';
import {
	CONTENT_TRANSFER,
	IS_SM_SCREEN,
} from '../../../@xmcloud/hooks/context/useSharedState';
import { handlePush } from '../../../@xmcloud/utils/helpers';
import { goTo, mainPaths } from '../../../@xmcloud/core/routes/paths';

const { inProgress, completed, create, transfersTitle, transfersDescription } =
	transfers;

const { noMatch } = mainPaths;

export const ContentTransferPage: FC = () => {
	const [query, setQuery] = useState(TransferQuery.completed);
	const [currentPage, setCurrentPage] = useState(1);
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);
	const { contentTransferExpFeat } = useBetaFeatures();

	const { state: contentTransferEnabled } = useSharedState(
		CONTENT_TRANSFER,
		contentTransferExpFeat,
	);

	useEffect(() => {
		if (!contentTransferEnabled) {
			//It blocks to reach current page if user try to add the url manually, if feature flag is disabled
			handlePush(noMatch);
		}
	}, [contentTransferEnabled]);

	return (
		<>
			{!isSmScreen && (
				<>
					<HeaderStack title={transfersTitle} mt={0}>
						<XMCSButton
							text={create}
							data-testid="create-transfer-button"
							onClick={() => handlePush(goTo.createTransferTo(1))}
						/>
					</HeaderStack>
					<Text variant="subtle" mt={3} mb={8}>
						{transfersDescription}
					</Text>
				</>
			)}
			<ToggleStack mb={4}>
				<TabList>
					<Tab
						onClick={() => {
							setCurrentPage(1);
							setQuery(TransferQuery.all);
						}}
						data-testid="recent-transfer-button"
					>
						{inProgress}
					</Tab>
					<Tab
						onClick={() => {
							setCurrentPage(1);
							setQuery(TransferQuery.completed);
						}}
						data-testid="my-transfer-button"
						defaultChecked
					>
						{completed}
					</Tab>
				</TabList>
			</ToggleStack>
			<TransfersTable {...{ query, currentPage, setCurrentPage }} />
			{Boolean(isSmScreen) && (
				<Footer>
					<XMCSButton
						text={create}
						data-testid="create-transfer-button"
						onClick={() => handlePush(goTo.createTransferTo(1))}
					/>
				</Footer>
			)}
		</>
	);
};
