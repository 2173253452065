import { EditProjectFormValues } from 'app/features/project/shared/types/form';
import { UseProjectMutationsReturn } from 'app/features/project/shared/types/mutations';

interface UpdateHandlerParams {
	projectId: string;
	mutations: UseProjectMutationsReturn;
}

/**
 * Creates handlers for submitting project updates.
 *
 * @param {UpdateHandlerParams} params - The parameters needed for update operations
 * @param {string} params.projectId - The ID of the project being updated
 * @param {UseProjectMutationsReturn} params.mutations - Mutation functions for project updates
 * @returns {Object} An object containing three handler functions:
 *   - handleBothChanges: Updates both project name and repository details
 *   - handleNameChange: Updates only the project name
 *   - handleRepoChange: Updates only the repository details
 */
export const createSubmitHandlers = ({
	projectId,
	mutations,
}: UpdateHandlerParams) => {
	const { updateProject, linkRepository, unlinkRepository } = mutations;

	const handleBothChanges = async (values: EditProjectFormValues) => {
		try {
			await unlinkRepository.mutateAsync(projectId);
			await linkRepository.mutateAsync({
				repository: values.repository,
				repositoryId: values.repositoryId.toString(),
				integrationId: values.sourceControlIntegrationId,
			});
			await updateProject.mutateAsync({
				name: values.name,
			});
		} catch (error) {
			throw error;
		}
	};

	const handleNameChange = async (values: EditProjectFormValues) => {
		try {
			await updateProject.mutateAsync({
				name: values.name,
			});
		} catch (error) {
			throw error;
		}
	};

	const handleRepoChange = async (values: EditProjectFormValues) => {
		try {
			await unlinkRepository.mutateAsync(projectId);
			await linkRepository.mutateAsync({
				repository: values.repository,
				repositoryId: values.repositoryId.toString(),
				integrationId: values.sourceControlIntegrationId,
			});
		} catch (error) {
			throw error;
		}
	};

	return {
		handleBothChanges,
		handleNameChange,
		handleRepoChange,
	};
};
