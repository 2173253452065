import { ESourceControl } from '@xmcloud/types';
import { EditProjectFormValues } from 'app/features/project/shared/types/form';
import { IGetProjectResponseV2 } from 'app/models/projectModel';
import { IRepositoryIntegrationDetails } from 'app/models/repositoriesModel';

interface FormStateParams {
	formik: {
		values: EditProjectFormValues;
		isValid: boolean;
		isSubmitting: boolean;
		dirty: boolean;
	};
	project: IGetProjectResponseV2 | null;
	repository: IRepositoryIntegrationDetails | null;
	integration: {
		isValid: boolean;
	};
	mutations: {
		updateProject: { isLoading: boolean };
		linkRepository: { isLoading: boolean };
		unlinkRepository: { isLoading: boolean };
	};
	selectionEnabled: boolean;
	noDefaultRepository: boolean;
}

export const useFormState = ({
	formik,
	project,
	repository,
	integration,
	mutations,
	selectionEnabled,
	noDefaultRepository,
}: FormStateParams) => {
	// Repository state
	const isRepositoryChanged =
		formik.values.repository !== project?.repository &&
		!!formik.values.repository &&
		!!formik.values.repositoryId;

	// Account state
	const hasAccount = !!project?.repository;

	// Provider state
	const initialProvider =
		repository?.provider === 2 ? ESourceControl.ADO : ESourceControl.GitHub;
	const isChangingProvider =
		!!repository && formik.values.provider !== initialProvider;

	// Form changes state
	const noChanges =
		!isRepositoryChanged && formik.values.name === project?.name;

	// Loading states
	const isMutating =
		mutations.updateProject.isLoading ||
		mutations.linkRepository.isLoading ||
		mutations.unlinkRepository.isLoading;

	// Form validity
	const hasValidRepository =
		!!formik.values.repository && !!formik.values.repositoryId;

	const isSubmitDisabled =
		!formik.isValid ||
		formik.isSubmitting ||
		!formik.dirty ||
		isMutating ||
		(!selectionEnabled && !integration.isValid) ||
		noChanges ||
		(!noDefaultRepository && !hasValidRepository);

	return {
		isRepositoryChanged,
		hasAccount,
		isChangingProvider,
		isSubmitDisabled,
	};
};
