import React from 'react';
import { ADORepo } from '../types';
import { SelectFormControl } from 'app/shared-components';
import { editCreateEnv } from '@xmcloud/core/messages/en';

const { adoRepo } = editCreateEnv;

interface RepositorySelectProps {
	repositories: ADORepo[];
	value: string;
	onChange: (value: string) => void;
	onFocus: () => void;
	isLoading: boolean;
	disabled: boolean;
	error: string;
	isRequired: boolean;
	isTouched: boolean;
}

export const RepositorySelect = React.memo(
	({
		repositories,
		value,
		onChange,
		onFocus,
		isLoading,
		disabled,
		error,
		isRequired,
		isTouched,
	}: RepositorySelectProps) => (
		<SelectFormControl
			label={adoRepo}
			name="adoRepository"
			currentValue={value}
			onChange={(option) => onChange(option.value)}
			onFocus={onFocus}
			options={repositories.map((repo) => ({
				value: repo.name,
				label: repo.name,
			}))}
			isLoading={isLoading}
			isDisabled={disabled}
			error={error}
			isRequired={isRequired}
			isInvalid={Boolean(error) && isTouched}
			pt={0}
		/>
	),
);
