import {
	MenuItem,
	Tooltip,
	MenuList,
	Link as ChakraLink,
	Tag,
	HStack,
	Text,
} from '@chakra-ui/react';
import { t } from '@transifex/native';
import { app, projects } from '../../../../@xmcloud/core/messages/en';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import { IGetProjectResponseV2 } from '../../../models/projectModel';
import { Icon, RowActions } from '../../../shared-components';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { handleTo } from '../../../../@xmcloud/utils/helpers';
import { createColumnHelper } from '@tanstack/react-table';
import { mdiFolderOutline } from '@mdi/js';
import { Link as ReactRouterLink } from 'react-router-dom';

const { deleteProject, canNotBeDeleted, nuOfEnvironments, nuOfEnv } = projects;
const { dateCreated, name, id } = app;

const { environments: environmentsPath, cmEnvironments: cmEnvironmentsPath } =
	goTo;

export type PickNameId = Pick<IGetProjectResponseV2, 'id' | 'name'>;
export type TProjects = IGetProjectResponseV2 & {
	nuOfEnvironments: number;
};

const columnHelper = createColumnHelper<TProjects>();

export const projectsTableColumns = ({
	projectDetails,
	setOpenModal,
	isSmScreen,
	enableCreateProjectV3,
}: {
	projectDetails: React.MutableRefObject<PickNameId>;
	setOpenModal: (openModal: boolean) => void;
	isSmScreen: boolean;
	enableCreateProjectV3: boolean;
}) => {
	return [
		columnHelper.accessor('name', {
			header: () => t(name),
			cell: (info) => {
				const isNewProject =
					!!info.row.original?.environments[0]?.type ||
					(!info.row.original?.environments.length &&
						enableCreateProjectV3);
				return (
					<HStack>
						<ChakraLink
							as={ReactRouterLink}
							to={handleTo(
								isNewProject
									? cmEnvironmentsPath(info.row.original.id)
									: environmentsPath(info.row.original.id),
							)}
							boxShadow="none !important"
						>
							<HStack>
								<Icon path={mdiFolderOutline} color="neutral" />
								<Text whiteSpace="wrap" noOfLines={3} maxW="sm">
									{info.row.original.name}
								</Text>
							</HStack>
						</ChakraLink>
						{isNewProject && <Tag colorScheme="info">Beta</Tag>}
					</HStack>
				);
			},
			maxSize: 96,
		}),
		columnHelper.accessor('id', {
			header: () => t(id),
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor('nuOfEnvironments', {
			cell: (info) => info.getValue(),
			header: () => t(isSmScreen ? nuOfEnv : nuOfEnvironments),
		}),
		columnHelper.accessor('lastUpdatedAt', {
			header: () => t(dateCreated),
			cell: (info) => getReadableDate(info.renderValue() as string),
		}),
		columnHelper.display({
			id: 'actions',
			cell: ({ row: { original: project } }) => (
				<RowActions ariaLabel="projects-table-action-menu">
					<MenuList>
						<Tooltip
							label={t(canNotBeDeleted)}
							isDisabled={!project.environments.length}
							shouldWrapChildren
						>
							<MenuItem
								isDisabled={!!project.environments.length}
								onClick={() => {
									projectDetails.current = {
										name: project.name,
										id: project.id,
									};
									setOpenModal(true);
								}}
								minW="3xs"
							>
								{t(deleteProject)}
							</MenuItem>
						</Tooltip>
					</MenuList>
				</RowActions>
			),
			maxSize: 0,
		}),
	];
};
