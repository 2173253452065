import { Stack, Link, Divider } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import {
	app,
	environment as envLabels,
	environments as environmentsLabels,
} from '../../../../@xmcloud/core/messages/en';
import {
	HeadingMd,
	Icon,
	IconText,
	LoadingSkeleton,
	TextBox,
} from '../../../shared-components';
import { mdiLink, mdiSourceBranch } from '@mdi/js';
import { t } from '@transifex/native';
import { mdiOpenInNew } from '@mdi/js';
import EdgeToken from '../../project/components/EdgeToken';
import {
	useEnvironmentDetails,
	useProjectDetails,
} from '../../../../@xmcloud/hooks';
import { ApiTokenComponent } from '../../project/components/EdgeAPIToken';
import { ContextID } from '../../project/components/ContextID';

const {
	type: typetxt,
	region: regiontxt,
	baseImageVersion: baseImageVersiontxt,
	id: idtxt,
	hostNamesAndUrls: hostNamesAndUrlstxt,
	hostName: hostNametxt,
	dashboard: dashboardtxt,
	authoringGraphQlEndpoint: authoringGraphQlEndpointtxt,
	authoringGraphQlEndpointTooltip,
	launchIDE: launchIDEtxt,
} = envLabels;
const { autoDeploy: autoDeploytxt } = environmentsLabels;
const { projectDetails: detailstxt } = app;

const EnvironmentDetails: React.FC = () => {
	const { environmentId, projectId } = useParams<{
		environmentId: string;
		projectId: string;
	}>();

	const { environment, isLoading: isEnvironmentLoading } =
		useEnvironmentDetails(environmentId!);

	const { region, isLoading: projectIsLoading } = useProjectDetails(
		projectId!,
	);

	const environmentDetails = environment;
	const isLoading = projectIsLoading || isEnvironmentLoading;
	const previewContextId = environmentDetails?.previewContextId || '';
	const liveContextId = environmentDetails?.liveContextId || '';
	const host = environmentDetails?.host || '';
	const repositoryBranch = environmentDetails?.repositoryBranch || '';

	if (isLoading) return <LoadingSkeleton height={36} amount={12} mt={10} />;

	const details = [
		{ title: idtxt, subTitle: environmentId },
		{
			title: typetxt,
			subTitle:
				environmentDetails?.tenantType === 'prod'
					? 'Production'
					: 'Non production',
		},
		{ title: regiontxt, subTitle: region },
		{
			title: baseImageVersiontxt,
			subTitle: `${environmentDetails?.sitecoreMajorVersion}.${environmentDetails?.sitecoreMinorVersion}`,
		},
	];

	const authoringGraphQlEndpoint = `https://${host}/sitecore/api/authoring/graphql/playground`;
	const dashboardUrl = `https://${host}/sitecore`;

	return (
		<>
			<Stack spacing={5} mt={5} pl={5} pb={[20, 20, 12, 12]}>
				<HeadingMd text={detailstxt} />
				<Stack>
					{!!repositoryBranch ? (
						<IconText
							text={repositoryBranch}
							IconName={mdiLink}
							iconSize={5}
							iconColor="neutral"
						/>
					) : null}
					{environmentDetails?.deployOnCommit ? (
						<IconText
							text={autoDeploytxt}
							IconName={mdiSourceBranch}
							iconSize={5}
							iconColor="neutral"
						/>
					) : null}
				</Stack>
				<ContextID
					{...{
						previewContextId,
						liveContextId,
						environmentId: environmentId!,
					}}
				/>
				{details.map(({ title, subTitle }) => (
					<TextBox {...{ title, subTitle }} key={title} />
				))}
				<Divider />
				<HeadingMd text={hostNamesAndUrlstxt} />
				<TextBox
					title={hostNametxt}
					subTitle={host}
					key={hostNametxt}
				/>
				<TextBox
					title={dashboardtxt}
					subTitle={dashboardUrl}
					key={dashboardtxt}
					isLink={true}
					iconSize={14}
					linkIcon={mdiOpenInNew}
					subTitleFontWeight="semibold"
					href={dashboardUrl}
				/>
				<TextBox
					subTitle=""
					title={authoringGraphQlEndpointtxt}
					key={authoringGraphQlEndpointtxt}
					linkIcon={mdiOpenInNew}
					tooltip={authoringGraphQlEndpointTooltip}
				>
					<Link
						isExternal
						href={authoringGraphQlEndpoint}
						boxShadow="none !important"
						fontWeight={'semibold'}
					>
						{t(launchIDEtxt)}
						<Icon path={mdiOpenInNew} ml={1} />
					</Link>
				</TextBox>
				<ApiTokenComponent host={host} />
				<EdgeToken />
				<Divider />
			</Stack>
		</>
	);
};

export { EnvironmentDetails as CombinedEnvironmentDetails };
