import { mdiFeather, mdiFolderOutline, mdiMonitor } from '@mdi/js';
import { app } from '../../../@xmcloud/core/messages/en';
import { goTo, mainPaths } from '../../../@xmcloud/core/routes/paths';

export const projectPageItems = [
	{ name: app.projects, to: mainPaths.projects, color: '', path: '' },
];
export const transferDetailsPageItems = [
	{
		name: app.contenttransfer,
		to: mainPaths.contenttransfer,
		color: '',
		path: '',
	},
];
export const environmentItems = (
	projectName: string | undefined,
	projectId: string,
	type: 'eh' | 'cm' | 'combined',
) => {
	if (type === 'eh') {
		return [
			{
				name: app.projects,
				to: mainPaths.projects,
				color: 'neutral',
				path: mdiFolderOutline,
			},
			{
				name: projectName ?? '...',
				to: goTo.ehEnvironments(projectId),
				color: 'purple',
				path: mdiFeather,
			},
		];
	}

	if (type === 'cm') {
		return [
			{
				name: app.projects,
				to: mainPaths.projects,
				color: 'neutral',
				path: mdiFolderOutline,
			},
			{
				name: projectName ?? '...',
				to: goTo.cmEnvironments(projectId),
				color: 'cyan',
				path: mdiMonitor,
			},
		];
	}

	return [
		{
			name: app.projects,
			to: mainPaths.projects,
			path: '',
			color: '',
		},
		{
			name: projectName ?? '...',
			to: goTo.environments(projectId),
			color: '',
			path: '',
		},
	];
};

export const deploymentLogsItems = ({
	projectName,
	projectId,
	environmentId,
	environmentName,
	environmentType,
}: {
	projectName: string | undefined;
	projectId: string;
	environmentId: string;
	environmentName: string | undefined;
	environmentType?: string;
}) => {
	if (environmentType === 'eh') {
		return [
			{
				name: app.projects,
				to: mainPaths.projects,
				color: '',
				path: '',
			},
			{
				name: projectName ?? '...',
				to: goTo.ehEnvironments(projectId),
				color: 'neutral',
				path: mdiFolderOutline,
			},
			{
				name: environmentName ?? '...',
				to: goTo.deployments(projectId, environmentId, environmentType),
				color: 'purple',
				path: mdiFeather,
			},
		];
	}
	if (environmentType === 'cm') {
		return [
			{
				name: app.projects,
				to: mainPaths.projects,
				color: '',
				path: '',
			},
			{
				name: projectName ?? '...',
				to: goTo.cmEnvironments(projectId),
				color: 'neutral',
				path: mdiFolderOutline,
			},
			{
				name: environmentName ?? '...',
				to: goTo.deployments(projectId, environmentId, environmentType),
				color: 'cyan',
				path: mdiMonitor,
			},
		];
	}

	return [
		{
			name: app.projects,
			to: mainPaths.projects,
			color: '',
			path: '',
		},
		{
			name: projectName ?? '...',
			to: goTo.projectDetails(projectId),
			color: '',
			path: '',
		},
		{
			name: environmentName ?? '...',
			to: environmentType
				? goTo.deployments(projectId, environmentId, environmentType)
				: goTo.oldDeployments(projectId, environmentId),
			color: '',
			path: '',
		},
	];
};
