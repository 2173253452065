import { AzureFeatureFlagValue } from '../@xmcloud/hooks/context/azureFeatureFlags';

export const AzureFeatureFlagsMock: AzureFeatureFlagValue[] = [
	{
		id: 'TBR',
		description: 'TBR[FE]',
		enabled: true,
		conditions: { clientFilters: [] },
	},
	{
		id: 'RegenerateContextId',
		description: 'RegenerateContextId[FE]',
		enabled: true,
		conditions: { clientFilters: [] },
	},
	{
		id: 'CreateProjectV3',
		description: 'CreateProjectV3[FE]',
		enabled: true,
		conditions: { clientFilters: [] },
	},
	{
		id: 'DefaultRhApiKey',
		description: 'DefaultRhApiKey[FE]',
		enabled: true,
		conditions: { clientFilters: [] },
	},
];
