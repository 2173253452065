import {
	Text,
	Link as SDSLink,
	MenuItem,
	MenuList,
	Box,
} from '@chakra-ui/react';
import { t } from '@transifex/native';
import { deployments } from '../../../../@xmcloud/core/messages/en';
import {
	DeployProcessStatus,
	IGetDeploymentResponse,
} from '../../../models/deploymentModel';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import { IconText, Link, RowActions } from '../../../shared-components';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import {
	handleHTMLTagLink,
	handlePush,
	handleTo,
} from '../../../../@xmcloud/utils/helpers';
import {
	mdiAlertCircle,
	mdiCheckCircle,
	mdiCircleOutline,
	mdiCloseCircleOutline,
} from '@mdi/js';
import { useMemo } from 'react';
import { StatusHeader } from '../../../shared-components/popover/DeploymentsStatusHeader';
import { createColumnHelper } from '@tanstack/react-table';
import { statusDescriptions } from '../../deployments/components/DeploymentsTable';
import { useGetEnvType } from '../hooks/useGetEnvType';

const {
	createDate,
	latestDeployment,
	queued,
	inprogress,
	complete,
	failed,
	skipped,
	viewLogs,
	cancel,
	deployBuildArtifact,
} = deployments;

const {
	deploymentLogs: deploymentLogsPath,
	oldDeploymentLogs: oldDeploymentLogsPath,
} = goTo;

const { Skipped, NotStarted, InProgress, Complete } = DeployProcessStatus;

export type TDeployments = IGetDeploymentResponse & { status: string };

const ReadableName = ({
	createdAt,
	projectId,
	environmentId,
	deploymentId,
	status,
	goToDeploymentLogs,
	envType,
}: {
	createdAt: string | undefined;
	projectId: string;
	environmentId: string;
	deploymentId: string;
	status: DeployProcessStatus | undefined;
	goToDeploymentLogs: any;
	envType: string | undefined;
}) => {
	const date = getReadableDate(createdAt);

	if (status === Skipped) {
		return <Text>{date}</Text>;
	}

	return (
		<Link
			text={date}
			to={handleTo(
				goToDeploymentLogs(
					projectId,
					environmentId,
					deploymentId,
					envType,
				),
			)}
		/>
	);
};

const columnHelper = createColumnHelper<TDeployments>();

export const useDeploymentsTableColumns = (
	setDeploymentId: (id: string) => void,
	setDeployToOtherEnvModal: (value: boolean) => void,
	setshowCancelDeploymentModal: (value: boolean) => void,
) => {
	const { isLegacyEnvsRoute, envType } = useGetEnvType();

	const goToDeploymentLogs = isLegacyEnvsRoute
		? oldDeploymentLogsPath
		: deploymentLogsPath;

	return useMemo(
		() => [
			columnHelper.accessor('createdAt', {
				header: () => t(createDate),
				cell: ({
					row: {
						original: {
							createdAt,
							projectId,
							environmentId,
							id,
							calculatedStatus,
						},
					},
				}) => (
					<ReadableName
						{...{
							createdAt,
							projectId,
							environmentId,
							deploymentId: id,
							status: calculatedStatus,
							goToDeploymentLogs,
							envType,
						}}
					/>
				),
			}),
			columnHelper.accessor('triggerMessage', {
				header: () => t(latestDeployment),
				cell: ({
					row: {
						original: { triggerMessage },
					},
				}) => {
					const isHTMLTag =
						triggerMessage && triggerMessage.startsWith('<a');
					const aTag =
						triggerMessage && handleHTMLTagLink(triggerMessage);

					return (
						<Text noOfLines={2} whiteSpace="wrap">
							{isHTMLTag ? (
								<SDSLink
									href={(aTag && aTag.link) ?? ''}
									isExternal
									boxShadow="none !important"
								>
									{aTag && aTag.text}
								</SDSLink>
							) : (
								triggerMessage
							)}
						</Text>
					);
				},
			}),
			columnHelper.accessor('calculatedStatus', {
				header: () => <StatusHeader allStatus={statusDescriptions} />,
				cell: ({
					row: {
						original: { calculatedStatus },
					},
				}) => {
					const status = statusIconsTextList[calculatedStatus];
					return (
						<IconText
							{...{
								text: status.text,
								...(status?.isIndeterminate && {
									isIndeterminate: true,
								}),
								...(status?.IconName && {
									IconName: status.IconName,
								}),
								...(status?.iconColor && {
									iconColor: status.iconColor,
								}),
							}}
						/>
					);
				},
			}),
			columnHelper.display({
				id: 'actions',
				cell: ({
					row: {
						original: {
							calculatedStatus,
							projectId,
							environmentId,
							id,
						},
					},
				}) =>
					calculatedStatus === Skipped ? (
						<Box h="10" />
					) : (
						<>
							<RowActions ariaLabel="environment-deployment-table-action-menu">
								<MenuList>
									<MenuItem
										onClick={() => {
											handlePush(
												goToDeploymentLogs(
													projectId,
													environmentId,
													id,
													envType,
												),
											);
										}}
									>
										{t(viewLogs)}
									</MenuItem>
									{Boolean(
										calculatedStatus === InProgress ||
											calculatedStatus === NotStarted,
									) && (
										<MenuItem
											onClick={() => {
												setDeploymentId(id);
												setshowCancelDeploymentModal(
													true,
												);
											}}
										>
											{t(cancel)}
										</MenuItem>
									)}
									{calculatedStatus === Complete && (
										<MenuItem
											onClick={() => {
												setDeploymentId(id);
												setDeployToOtherEnvModal(true);
											}}
										>
											{t(deployBuildArtifact)}
										</MenuItem>
									)}
								</MenuList>
							</RowActions>
						</>
					),
				maxSize: 0,
			}),
		],
		[
			goToDeploymentLogs,
			envType,
			setDeploymentId,
			setshowCancelDeploymentModal,
			setDeployToOtherEnvModal,
		],
	);
};

export const statusIconsTextList = [
	{ IconName: mdiCircleOutline, text: queued, iconColor: 'warning' },
	{ isIndeterminate: true, text: inprogress },
	{ IconName: mdiCheckCircle, text: complete, iconColor: 'success' },
	{ IconName: mdiAlertCircle, text: failed, iconColor: 'danger' },
	{
		IconName: mdiCloseCircleOutline,
		text: skipped,
		iconColor: 'neutral',
	},
];
