import { Card, CardBody, CardHeader, Flex } from '@chakra-ui/react';
import { HeadingSm, Text } from '../text';

interface Props {
	title: string;
	description?: string;
	children?: React.ReactNode;
}
export const CardBox: React.FC<Props> = ({
	title,
	description = '',
	children,
}) => {
	return (
		<Card variant="outline" data-testid="card-box">
			<CardHeader>
				<Flex justifyContent="space-between" gap={2}>
					<HeadingSm text={title} />
					<>{children}</>
				</Flex>
			</CardHeader>
			<CardBody pt="0">
				<Text text={description} maxW={['100%', '90%']} />
			</CardBody>
		</Card>
	);
};
