import { assertError } from 'app/features/project/shared/utils/error';
import {
	useGetADOProfile,
	useGetADOOrganizations,
	useGetADOProjects,
	useGetADORepositories,
} from 'app/services/adoApi';

import {
	ADOSelectionProps,
	ProfileResult,
	OrganizationsResult,
	ProjectsResult,
	RepositoriesResult,
	ADOSelectionResult,
} from 'app/features/project/integrations/ado/types';

export const useADOProfile = (
	token: string,
	integrationId: string,
	shouldEnable: boolean,
): ProfileResult => {
	const {
		data: profileResponse,
		isLoading,
		error,
	} = useGetADOProfile(
		token,
		integrationId,
		shouldEnable && !!token && !!integrationId,
	);

	return {
		profile: profileResponse?.data,
		isLoading,
		error: assertError(error),
	};
};

export const useADOOrganizations = (
	token: string,
	publicAlias: string,
	shouldEnable: boolean,
): OrganizationsResult => {
	const {
		data: organizationsResponse,
		isLoading,
		error,
	} = useGetADOOrganizations({
		token,
		publicAlias,
		_enabled: shouldEnable && !!publicAlias && !!token,
	});

	return {
		organizations: organizationsResponse?.data?.value ?? [],
		isLoading,
		error: assertError(error),
	};
};

export const useADOProjects = (
	token: string,
	organization: string,
	shouldEnable: boolean,
): ProjectsResult => {
	const {
		data: projectsResponse,
		isLoading,
		error,
	} = useGetADOProjects({
		token,
		organization,
		_enabled: shouldEnable && !!organization && !!token,
	});

	return {
		projects: projectsResponse?.data?.value ?? [],
		isLoading,
		error: assertError(error),
	};
};

export const useADORepositories = ({
	token,
	organization,
	project,
	shouldEnable,
}: {
	token: string;
	organization: string;
	project: string;
	shouldEnable: boolean;
}): RepositoriesResult => {
	const {
		data: repositoriesResponse,
		isLoading,
		error,
	} = useGetADORepositories({
		token,
		organization,
		project,
		_enabled: !!organization && !!project && !!token && shouldEnable,
	});

	return {
		repositories: repositoriesResponse?.data?.value ?? [],
		isLoading,
		error: assertError(error),
	};
};

/**
 * Custom hook that manages Azure DevOps (ADO) integration data fetching and selection state
 *
 * This hook coordinates the fetching of various ADO resources in a hierarchical manner:
 * 1. Fetches the access token using the provided integration ID
 * 2. Retrieves the user profile using the token
 * 3. Gets organizations associated with the user
 * 4. Loads projects for the selected organization
 * 5. Fetches repositories for the selected project
 *
 * @param {Object} params - The parameters object
 * @param {string} params.integrationId - The ID of the ADO integration
 * @param {string} params.selectedOrg - The selected organization name
 * @param {string} params.selectedProject - The selected project name
 * @param {boolean} [params.enabled=true] - Flag to enable/disable the hook
 *
 * @returns {ADOSelectionResult} An object containing:
 *  - data: The fetched organizations, projects, and repositories
 *  - isLoading: Loading states for each request
 *  - error: Any error that occurred during the requests
 */
export const useADOSelection = ({
	integrationId,
	selectedOrg,
	selectedProject,
	isValidProviderToken,
	enabled = true,
	token,
}: ADOSelectionProps): ADOSelectionResult => {
	const shouldEnable = enabled && isValidProviderToken;

	const {
		profile,
		isLoading: isLoadingProfile,
		error: profileError,
	} = useADOProfile(token ?? '', integrationId, shouldEnable);

	const {
		organizations,
		isLoading: isLoadingOrgs,
		error: orgsError,
	} = useADOOrganizations(
		token ?? '',
		profile?.publicAlias ?? '',
		shouldEnable,
	);

	const {
		projects,
		isLoading: isLoadingProjects,
		error: projectsError,
	} = useADOProjects(token ?? '', selectedOrg ?? '', shouldEnable);

	const {
		repositories,
		isLoading: isLoadingRepos,
		error: reposError,
	} = useADORepositories({
		token: token ?? '',
		organization: selectedOrg ?? '',
		project: selectedProject ?? '',
		shouldEnable,
	});

	const error = profileError || orgsError || projectsError || reposError;

	return {
		data: {
			organizations,
			projects,
			repositories,
		},
		isLoading: {
			profile: isLoadingProfile,
			organizations: isLoadingOrgs,
			projects: isLoadingProjects,
			repositories: isLoadingRepos,
		},
		error: error,
	};
};
