import { FormikHelpers } from 'formik';
import { EditProjectFormValues } from '../../../shared/types/form';
import { parseADORepository } from '../helpers/parsers';
import { IGetProjectResponseV2 } from 'app/models/projectModel';
import { IRepositoryIntegrationDetails } from 'app/models/repositoriesModel';
import { ESourceControl } from '@xmcloud/types';

/**
 * Creates a handler function to revert form values back to their initial state
 * based on the project and repository data.
 *
 * @param setValues - Formik helper function to update form values
 * @param project - Current project data containing repository information
 * @param repository - Repository integration details containing provider information
 * @returns A function that resets form values to their initial state
 */
export const createSwitchBackHandler = (
	setValues: FormikHelpers<EditProjectFormValues>['setValues'],
	project: IGetProjectResponseV2 | null,
	repository: IRepositoryIntegrationDetails | null,
) => {
	return () => {
		const { adoOrganization, adoProjectName, adoRepository } =
			parseADORepository(project?.repository ?? '');
		const initialProvider =
			repository?.provider === 2
				? ESourceControl.ADO
				: ESourceControl.GitHub;

		setValues(
			(prevValues) => ({
				...prevValues,
				provider: initialProvider,
				repository: project?.repository ?? '',
				repositoryId: project?.repositoryId ?? '',
				sourceControlIntegrationId:
					project?.sourceControlIntegrationId ?? '',
				...(initialProvider === ESourceControl.ADO
					? {
							adoOrganization,
							adoProjectName,
							adoRepository,
						}
					: {
							adoOrganization: '',
							adoProjectName: '',
							adoRepository: '',
						}),
			}),
			true,
		);
	};
};
