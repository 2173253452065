import { useToast } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { useParams } from 'react-router';
import { environmentVariable } from '../../../../../@xmcloud/core/messages/en';
import { IEnvironmentVariableDetails } from '../../../../models/environmentModel';
import {
	useDeleteEnvironmentVariableMutation,
	useUpsertEnvironmentVariableMutation,
} from '../../../../services/environments';
import EditCreateVariableForm from '../../components/EditCreateVariableForm';
import { useQueryClient } from 'react-query';
import config from '../../../../config/config';
const { targetRH, targetAll, updatedSuccessfully } = environmentVariable;

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
	variable: IEnvironmentVariableDetails;
}

const EditVariable: React.FC<Props> = ({
	openModal,
	setOpenModal,
	variable,
}) => {
	const { environmentId } = useParams<{ environmentId: string }>();
	const queryClient = useQueryClient();
	const toast = useToast();

	const { mutate: updateVariable, isLoading: isUpdateLoading } =
		useUpsertEnvironmentVariableMutation();

	const { mutate: deleteVariable, isLoading: isDeleteLoading } =
		useDeleteEnvironmentVariableMutation();

	function handleUpsert(values: any) {
		let target =
			values.target === targetRH
				? values.renderingHost
				: values.target === targetAll
					? null
					: values.target;

		updateVariable(
			{
				environmentId: environmentId!,
				variableName: values.name,
				data: {
					secret: values.secret,
					value: values.value,
					target: target,
				},
			},
			{
				onSuccess: (data, variables) => {
					queryClient.invalidateQueries(
						config.environments.environment_variables.queryKey(
							variables.environmentId,
						),
					);
					setOpenModal(false);
					toast({
						status: 'success',
						description: t(updatedSuccessfully),
					});
				},
			},
		);
	}

	const handleUpdateVariable = (values: any) => {
		deleteVariable(
			{
				environmentId: environmentId!,
				variableName: variable.name,
				target: Boolean(variable.target)
					? variable.target
					: environmentVariable.targetAll,
			},
			{
				onSuccess: () => {
					handleUpsert(values);
				},
			},
		);
	};
	const isLoading = isUpdateLoading || isDeleteLoading;

	return (
		<EditCreateVariableForm
			{...{
				openModal,
				setOpenModal,
				onSubmit: handleUpdateVariable,
				variableToEdit: variable,
				isEdit: true,
				isLoading,
			}}
		/>
	);
};

export default EditVariable;
