import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, MenuItem, Tooltip, useToast } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { environment } from '../../../../@xmcloud/core/messages/en';
import { useGetEnvironmentRestartStatus } from '../../../services/environments';
import { IconText } from '../../../shared-components';
import RestartEnvironment from './RestartEnvironment';
import {
	EnvironmentRestartStatus,
	IGetRestartEnvironmentResponse,
} from '../../../models/environmentModel';
import { AxiosError } from 'axios';
import { useSharedState } from '../../../../@xmcloud/hooks';
import { IS_DELETED_ENVIRONMENT } from '../../../../@xmcloud/hooks/context/useSharedState';
const {
	restart,
	requestRestart,
	restarting,
	restartSuccessTitle,
	restartSuccessDescription,
	restartFailedTitle,
	restartFailedDescription,
} = environment;

const INTERVAL_IN_MS = 10000;
const RESTART_ALREADY_RUNNING =
	'Deployment restart already running for environment';

const { NotStarted, Completed, Failed, Running } = EnvironmentRestartStatus;

const RestartMenuItem: FC<{ environmentId: string }> = ({ environmentId }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isEnabled, setIsEnabled] = useState(false);
	const [isProgress, setIsProgress] = useState(false);
	const [wasProgress, setWasProgress] = useState(false);
	const intervalInMs = useRef(0);
	const { state: deletedEnvironment } = useSharedState(
		IS_DELETED_ENVIRONMENT,
		'',
	);
	const isDeletedEnvironment = deletedEnvironment === environmentId;

	const toast = useToast();

	const onEnableReFetch = () => {
		intervalInMs.current = INTERVAL_IN_MS;
		setIsEnabled(true);
		setIsProgress(true);
	};
	const onReset = () => {
		intervalInMs.current = 0;
		setIsEnabled(false);
		setIsProgress(false);
		setWasProgress(false);
	};

	const onFetchSuccess = useCallback(
		(data: IGetRestartEnvironmentResponse) => {
			const status = data.restartStatus ?? NotStarted;

			const isCompleted = status === Completed;
			const isFailed = status === Failed;
			const isRunning = status === Running;

			if (isRunning) {
				onEnableReFetch();
				setWasProgress(true);
				return;
			}

			if ((isCompleted || isFailed) && wasProgress) {
				onReset();

				toast({
					title: isCompleted
						? restartSuccessTitle
						: restartFailedTitle,
					description: isCompleted
						? restartSuccessDescription
						: restartFailedDescription,
					status: isCompleted ? 'success' : 'error',
					duration: 5000,
				});

				return;
			}
		},
		[toast, wasProgress],
	);

	const { refetch } = useGetEnvironmentRestartStatus({
		environmentId,
		enabled: isEnabled && !isDeletedEnvironment,
		refetchInterval: intervalInMs.current,
		onSuccess: onFetchSuccess,
	});

	useEffect(() => {
		if (!isDeletedEnvironment) {
			refetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDeletedEnvironment]);

	const onMutationSuccess = (data: any) => {
		intervalInMs.current = INTERVAL_IN_MS;
		setIsEnabled(true);
	};

	const onSubmit = () => {
		setIsProgress(true);
	};

	const onMutationError = (e: AxiosError) => {
		// checking error title when the environment is already restarting
		const isAlreadyStarted =
			//@ts-ignore
			e?.response?.data?.detail === RESTART_ALREADY_RUNNING;
		isAlreadyStarted ? onEnableReFetch() : onReset();
	};

	return (
		<MenuItem
			data-testid="restart-environment-menu-item"
			isDisabled={isProgress}
			onClick={() => setIsOpen(true)}
		>
			<>
				<Tooltip label={t(isProgress ? restarting : requestRestart)}>
					<Box display="flex" flexDir="row" gap={6}>
						{t(restart)}
						{isProgress && <IconText text={''} isIndeterminate />}
					</Box>
				</Tooltip>
				{isOpen && Boolean(environmentId) && (
					<RestartEnvironment
						openModal={isOpen}
						setOpenModal={setIsOpen}
						environmentId={environmentId}
						onSuccess={onMutationSuccess}
						onSubmit={onSubmit}
						onError={onMutationError}
					/>
				)}
			</>
		</MenuItem>
	);
};

export default RestartMenuItem;
