import {
	useGetProject as useGetProjectDetails,
	useLinkRepositoryMutation,
	useUnlinkRepositoryMutation,
	useUpdateProjectMutation,
} from 'app/services/projects';
import { UseProjectMutationsReturn } from '../shared/types/mutations';

/**
 * Hook to fetch project details by project ID
 * @param projectId - The unique identifier of the project
 * @returns Project details query result
 */
export const useProject = (projectId: string) => {
	return useGetProjectDetails({ id: projectId });
};

/**
 * Hook to get project-related mutation operations
 * @param projectId - The unique identifier of the project
 * @returns Object containing mutation functions for updating project, linking and unlinking repository
 */
export const useProjectMutations = (
	projectId: string,
): UseProjectMutationsReturn => {
	const updateProjectMutation = useUpdateProjectMutation({
		id: projectId,
	});
	const unlinkRepositoryMutation = useUnlinkRepositoryMutation();
	const linkRepositoryMutation = useLinkRepositoryMutation({
		projectId,
	});

	return {
		updateProject: updateProjectMutation,
		unlinkRepository: unlinkRepositoryMutation,
		linkRepository: linkRepositoryMutation,
	};
};
