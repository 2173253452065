import { useState } from 'react';
import { Tooltip, useToast } from '@chakra-ui/react';
import {
	environmentVariable,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import {
	EnvVars,
	EnvVarsAddButton,
	EnvVarsForm,
	EnvVarsTable,
	HasEnvVars,
	EnvVariable,
	EnvVariableInput,
} from '../../../features/custom-environment-variables';
import { t } from '@transifex/native';
import {
	useDeleteEnvironmentVariableMutation,
	useGetEnvironmentVariables,
	useUpsertEnvironmentVariableMutation,
} from '../../../services/environments';
import { useQueryClient } from 'react-query';
import config from '../../../config/config';
import { useComponentHealthy } from '../../../../@xmcloud/hooks';
import {
	AlertDialogConfirmationModal,
	Wrap,
	Text,
	LoadingSkeleton,
} from '../../../shared-components';
import { VariablesPageLegacy } from './legacy-pages/VariablesPageLegacy';
import { EnvironmentTypesEnum } from '../../create-project/helpers';
import { useGetEnvType } from '../hooks/useGetEnvType';
import { pageSize } from '../../../../@xmcloud/utils/constants';

const {
	errMsg4,
	errMsg1,
	createVariable: createVariabletxt,
	configuration: configurationtxt,
} = systemHealthStatus;
const { CM, EH } = EnvironmentTypesEnum;

const TARGET = {
	[CM]: 'CM',
	[EH]: 'EH',
	combined: null,
	environments: null,
};

export const VariablesPageLatest: React.FC = () => {
	const { projectId, environmentId, envType } = useGetEnvType();
	const { isConfigurationHealthy, isError } = useComponentHealthy({});
	const { data: envVariables, isLoading } = useGetEnvironmentVariables({
		environmentId: environmentId!,
	}) || { data: [] };

	const { mutateAsync: createVariable } =
		useUpsertEnvironmentVariableMutation();

	const { mutateAsync: deleteVariable, isLoading: isDeletionInProgress } =
		useDeleteEnvironmentVariableMutation();

	const queryClient = useQueryClient();
	const toast = useToast();

	const { environment_variables } = config.environments;
	const { createdSuccessfully } = environmentVariable;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [variableToDelete, setVariableToDelete] =
		useState<EnvVariableInput | null>(null);
	const targetType = envType as keyof typeof TARGET;

	const handleCreateVariable = (values: EnvVariableInput) => {
		return createVariable(
			{
				environmentId: environmentId!,
				variableName: values.name,
				data: {
					secret: values.secret,
					value: values.value,
					target: TARGET[targetType] as string,
				},
			},
			{
				onSuccess: (_, variables) => {
					queryClient.invalidateQueries(
						environment_variables.queryKey(variables.environmentId),
					);
					toast({
						status: 'success',
						description: t(createdSuccessfully),
					});
				},
			},
		);
	};

	const handleDeleteVariable = (variable: EnvVariableInput) => {
		setVariableToDelete(variable);
		setIsModalOpen(true);
	};

	const confirmDeleteVariable = () => {
		if (!variableToDelete) return;

		deleteVariable(
			{
				environmentId: environmentId!,
				variableName: variableToDelete.name,
			},
			{
				onSuccess: (_, variables) => {
					queryClient.invalidateQueries(
						config.environments.environment_variables.queryKey(
							variables.environmentId,
						),
					);
					toast({
						status: 'success',
						description: t(environmentVariable.deletedSuccessfully),
					});
					setIsModalOpen(false);
				},
			},
		);
	};

	const healthStatusLabel = isError
		? t(errMsg1)
		: t(errMsg4, {
				variableTxt: configurationtxt,
				variableTxt2: createVariabletxt,
			});

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} />;

	return (
		<EnvVars
			isExistingProject={Boolean(projectId)}
			initialVariables={(envVariables?.data as EnvVariable[]) || []}
		>
			<Tooltip
				label={healthStatusLabel}
				isDisabled={isConfigurationHealthy}
			>
				<Wrap alignSelf="flex-end">
					<EnvVarsAddButton
						variant="solid"
						isDisabled={!isConfigurationHealthy}
					/>
				</Wrap>
			</Tooltip>
			<EnvVarsForm onMutationSubmit={handleCreateVariable} />
			<HasEnvVars>
				<EnvVarsTable onMutationDelete={handleDeleteVariable} />
			</HasEnvVars>
			<AlertDialogConfirmationModal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onConfirm={confirmDeleteVariable}
				title={environmentVariable.delete}
				isLoading={isDeletionInProgress}
			>
				<Text text={t(environmentVariable.deleteConfirmation)}></Text>
			</AlertDialogConfirmationModal>
		</EnvVars>
	);
};

export function VariablesPage(): JSX.Element {
	const { isLegacyEnvsRoute } = useGetEnvType();

	if (isLegacyEnvsRoute) {
		return <VariablesPageLegacy />;
	}

	return <VariablesPageLatest />;
}
