import { useGetPaginatedGithubRepositories } from 'app/services/githubApi';

import {
	GitHubSelectionProps,
	RepositoriesResult,
} from 'app/features/project/integrations/github/types';
import { assertError } from 'app/features/project/shared/utils/error';
import { useCallback, useMemo, useRef } from 'react';
import { debounce } from 'lodash';

export const useGitHubSelection = ({
	integrationId,
	isValidProviderToken,
	enabled = true,
	token,
}: GitHubSelectionProps): RepositoriesResult => {
	const shouldEnable = enabled && isValidProviderToken;
	const initialLoadComplete = useRef(false);
	const searchQuery = useRef('');
	const isSearching = useRef(false);

	const handleSuccess = (lastPage: any, hasNextPage: boolean) => {
		if (!initialLoadComplete.current) {
			return;
		}

		if (!isSearching.current || !searchQuery.current) {
			return;
		}

		const hasMatch = lastPage?.some((repo: any) =>
			repo.name.toLowerCase().includes(searchQuery.current.toLowerCase()),
		);

		if (!hasMatch && hasNextPage) {
			fetchNextPage();
		}
	};

	const result = useGetPaginatedGithubRepositories(
		token,
		integrationId,
		shouldEnable && !!token && !!integrationId,
		(data) => {
			handleSuccess(data, !!result.hasNextPage);
		},
	);

	const {
		data: allRepositories,
		hasNextPage,
		isFetchingNextPage,
		isLoading,
		isFetching,
		error,
		fetchNextPage,
	} = result;

	if (allRepositories?.length && !initialLoadComplete.current) {
		initialLoadComplete.current = true;
	}

	const handleSearch = useCallback(
		(query: string) => {
			searchQuery.current = query;

			if (!query) {
				isSearching.current = false;
				return;
			}

			isSearching.current = true;

			const hasMatch = allRepositories?.some((repo) =>
				repo.name.toLowerCase().includes(query.toLowerCase()),
			);

			if (!hasMatch && hasNextPage && !isFetchingNextPage) {
				fetchNextPage();
			}
		},
		[allRepositories, fetchNextPage, hasNextPage, isFetchingNextPage],
	);

	const debouncedSearch = useMemo(
		() => debounce(handleSearch, 300),
		[handleSearch],
	);

	return {
		repositories: allRepositories ?? [],
		hasNextPage: !!hasNextPage,
		isLoading,
		isFetchingNextPage,
		isFetching,
		handleSearch: debouncedSearch,
		handleFetchNextPage: fetchNextPage,
		error: assertError(error),
	};
};
