import { string, object } from 'yup';
import {
	firstStep,
	projects,
	secondStep,
	connections,
	editProject,
} from '../core/messages/en';
import '../utils/yupUtils';

const { requiredName } = projects;
const { maxCharacters } = firstStep;
const { repoRequired, orgRequired, projectNameRequired } = secondStep;
const { requiredConnection } = connections;
const { requiredProvider, forbiddenChars, cannotEndWith } = editProject;

export const editProjectValidationSchema = ({
	isADOProvider = false,
	noDefaultRepository = false,
}: {
	isADOProvider?: boolean;
	noDefaultRepository?: boolean;
}) => {
	return object({
		name: string()
			.required(requiredName)
			.max(100, maxCharacters)
			.testForbidChars(forbiddenChars)
			.endsWithForbidChars(cannotEndWith),
		providerName: noDefaultRepository
			? string().nullable()
			: string().required(requiredProvider),
		sourceControlIntegrationId: object().when('providerName', {
			is: (val: string) => !!val,
			then: () => string().required(requiredConnection),
			otherwise: () => string().optional().nullable(),
		}),
		repository: object().when('providerName', {
			is: (val: string) => !!val,
			then: () => string().required(repoRequired),
			otherwise: () => string().optional().nullable(),
		}),
		adoOrganization: object().when('providerName', {
			is: (val: string) => val === 'ADO',
			then: () => string().required(orgRequired),
			otherwise: () => string().optional().nullable(),
		}),
		adoProjectName: object().when('providerName', {
			is: (val: string) => val === 'ADO',
			then: () => string().required(projectNameRequired),
			otherwise: () => string().optional().nullable(),
		}),
	});
};
