export enum DeployProcessStatus {
	NotStarted,
	InProgress,
	Complete,
	Failed,
	Skipped,
}

export interface IGetDeploymentResponse {
	projectId: string;
	organizationId: string;
	environmentId: string;
	id: string;
	provisioningStatus: DeployProcessStatus;
	provisioningStartedAt: string | null;
	provisioningCompletedAt: string | null;
	buildStatus: DeployProcessStatus;
	buildStartedAt: string | null;
	buildCompletedAt: string | null;
	deploymentStatus: DeployProcessStatus;
	deploymentStartedAt: string | null;
	deploymentCompletedAt: string | null;
	postActionStatus: DeployProcessStatus;
	postActionStartedAt: string | null;
	postActionCompletedAt: string | null;
	vercelStatus: DeployProcessStatus;
	calculatedStatus: DeployProcessStatus;
	createdBy: string;
	createdAt: string;
	startedAt: string;
	completedAt: string | null;
	triggerMessage: string | null;
	isCanceled: boolean;
	projectName?: string;
	environmentName?: string;
	environmentType?: string | null;
}

export interface ICreateDeploymentResponse {
	createdAt: string;
	createdBy: string;
	environmentId: string;
	id: string;
	organizationId: string;
	projectId: string;
}

export interface IPromoteDeploymentResponse {
	createdAt: string;
	createdBy: string;
	environmentId: string;
	id: string;
	organizationId: string;
	projectId: string;
}

export interface IDeploymentsStatusCountResponse {
	completed: number;
	failed: number;
	inProgress: number;
	notStarted: number;
}

export type IPaginatedDeploymentsResponse = {
	totalCount: number;
	pageSize: number;
	pageNumber: number;
	data: IGetDeploymentResponse[];
};
