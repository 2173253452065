import { FC, useState } from 'react';
import { goTo } from '../../../@xmcloud/core/routes/paths';
import { handlePush } from '../../../@xmcloud/utils/helpers';
import {
	EditProjectProvider,
	useProjectDetails,
} from '../../../@xmcloud/hooks';
import { EditProjectForm as EditProjectFormLegacy } from './EditProjectForm';
import { useParams } from 'react-router-dom';
import { ModalWrapper } from '../../shared-components';
import { projects } from '../../../@xmcloud/core/messages/en';
import { EditProjectForm } from 'app/features/project/components/EditProject/EditProjectForm';

const { editTitle } = projects;
const { environments, projectDetails } = goTo;

export const EditProjectMain: FC = () => {
	const [isOpen, setIsOpen] = useState(true);
	const { projectId = '' } = useParams<{ projectId: string }>();
	const { isSplittedProject } = useProjectDetails(projectId!);

	function onClose() {
		setIsOpen(false);
		handlePush(
			isSplittedProject
				? projectDetails(projectId!)
				: environments(projectId!),
		);
	}

	return (
		<ModalWrapper
			isOpen={isOpen}
			title={editTitle}
			onClose={onClose}
			dataTestId="edit-project-modal-"
			size="lg"
		>
			{isSplittedProject ? (
				<EditProjectForm {...{ onClose, projectId }} />
			) : (
				<EditProjectProvider>
					<EditProjectFormLegacy {...{ onClose }} />
				</EditProjectProvider>
			)}
		</ModalWrapper>
	);
};
