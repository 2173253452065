import { useQueryClient } from 'react-query';
import config from '../../app/config/config';
import { IGetEnvironmentResponseV2 } from '../../app/models/environmentModel';
import { useGetEnvironment } from '../../app/services/environments';
import { QueryResponse } from '../types';

const {
	environments: { environment: environmentConfig },
} = config;

export function useEnvironmentDetails(
	environmentId: string,
	shouldEnabled?: boolean,
) {
	const queryClient = useQueryClient();
	const environmentQueryData = queryClient.getQueryData(
		environmentConfig.queryKey(environmentId),
	) as QueryResponse<IGetEnvironmentResponseV2>;

	const { data: environmentData, isLoading } = useGetEnvironment({
		id: environmentId,
		enabled: shouldEnabled ? true : !Boolean(environmentQueryData?.data),
	});

	const environment = environmentQueryData?.data || environmentData?.data;
	const environmentType = environment?.type;
	const environmentName = environment?.name || '';
	const cmEnvironmentId =
		environment?.editingHostEnvironmentDetails?.cmEnvironmentId || '';
	const platformTenantId = environment?.platformTenantId || '';
	const repositoryRelativePath =
		environment?.repositoryDetails?.repositoryRelativePath ||
		'./headapps/nextjs-starter';
	const repository = environment?.repositoryDetails?.repository || '';
	const repositoryLinkedAt =
		environment?.repositoryDetails?.repositoryLinkedAt || '';

	return {
		environmentName,
		cmEnvironmentId,
		environment,
		isLoading,
		environmentType,
		platformTenantId,
		repositoryRelativePath,
		repository,
		repositoryLinkedAt,
	};
}
