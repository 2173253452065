import { ModalFooter, Button } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { app, editProject } from '@xmcloud/core/messages/en';

interface FormActionsProps {
	isSubmitting: boolean;
	isDisabled: boolean;
	isRepositoryChanged: boolean;
	hasAccount: boolean;
	hasError: boolean;
	onClose: () => void;
}

export const FormActions = ({
	isSubmitting,
	isDisabled,
	isRepositoryChanged,
	hasAccount,
	hasError,
	onClose,
}: FormActionsProps) => {
	const showConfirmationBtn = isRepositoryChanged && !hasError && hasAccount;

	return (
		<ModalFooter gap={3}>
			{showConfirmationBtn ? (
				<Button
					type="submit"
					isLoading={isSubmitting}
					isDisabled={isDisabled}
					colorScheme="danger"
					variant="solid"
					data-testid="save-confirm-button-danger"
				>
					{t(confirmation2)}
				</Button>
			) : (
				<Button
					type="submit"
					isLoading={isSubmitting}
					isDisabled={isDisabled}
					data-testid="Save-confirm-button"
				>
					{t(save)}
				</Button>
			)}

			<Button onClick={onClose} variant="ghost">
				{t(cancel)}
			</Button>
		</ModalFooter>
	);
};

const { save, cancel } = app;
const { confirmation2 } = editProject;
