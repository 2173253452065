import { FormikHelpers } from 'formik';
import { Provider } from '../../../shared/types/project';
import { EditProjectFormValues } from '../../../shared/types/form';

/**
 * Creates a handler function that updates form values when switching between source control providers.
 * When a new provider is selected, it clears all repository-related fields and provider-specific fields
 * to prevent invalid data from persisting across provider changes.
 *
 * @param setValues - Formik's setValues function to update form state
 * @returns A function that takes a Provider and updates the form accordingly
 */
export const createProviderChangeHandler = (
	setValues: FormikHelpers<EditProjectFormValues>['setValues'],
) => {
	return (provider: Provider) => {
		setValues((prev) => ({
			...prev,
			provider,
			// Clear repository related fields
			repository: '',
			repositoryId: '',
			sourceControlIntegrationId: '',
			// Clear ADO specific fields
			adoOrganization: '',
			adoProjectName: '',
			adoRepository: '',
		}));
	};
};
