import { editCreateEnv } from '@xmcloud/core/messages/en';
import { SelectFormControl } from 'app/shared-components';
import React, { useMemo } from 'react';

interface OrganizationSelectProps {
	organizations: { accountId: string; accountName: string }[];
	value: string;
	onChange: (value: string) => void;
	onFocus: () => void;
	isLoading: boolean;
	error: string;
	isRequired: boolean;
	isDisabled?: boolean;
	isTouched?: boolean;
}

const { adoOrg } = editCreateEnv;

export const OrganizationSelect = React.memo(
	({
		organizations,
		value,
		onChange,
		onFocus,
		isLoading,
		error,
		isRequired,
		isDisabled,
		isTouched,
	}: OrganizationSelectProps) => {
		const adoOrganizations = useMemo(
			() =>
				organizations.map((r: any) => ({
					label: r.accountName,
					value: r.accountName,
				})) || [],
			[organizations],
		);
		return (
			<SelectFormControl
				label={adoOrg}
				name={'adoOrganization'}
				currentValue={value}
				onChange={(option) => onChange(option.value)}
				onFocus={() => onFocus && onFocus()}
				options={adoOrganizations}
				isLoading={isLoading}
				error={error}
				isRequired={isRequired}
				isInvalid={Boolean(error) && isTouched}
				isDisabled={isDisabled}
				pt={0}
			/>
		);
	},
);
