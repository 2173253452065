import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { t } from '@transifex/native';
import {
	fetchLastSuccessfulLoginOrganizationId,
	ORGANIZATION,
} from '../../auth/Auth0Storage';
import { Icon } from '..';

interface Props {
	items: { name: string; to: string; color: string; path: string }[];
}

export const XMCSBreadCrumb: React.FC<Props> = ({ items }) => {
	const orgId = fetchLastSuccessfulLoginOrganizationId();
	return (
		<Breadcrumb mb="2">
			{items.map(({ name, to, color, path }) => (
				<BreadcrumbItem
					{...(items[items.length - 1].name === name && {
						isCurrentPage: true,
					})}
					key={to}
					data-testid={`${name}-breadcrumb-item`}
				>
					{Boolean(path) && (
						<Icon
							path={path}
							color={color}
							layerStyle="icon.subtle"
							boxSize="icon.3xs"
							mr="1"
						/>
					)}
					<BreadcrumbLink
						as={Link}
						to={to ? `${to}?${`${ORGANIZATION}=${orgId}`}` : '#'}
						maxW="3xs"
						noOfLines={1}
					>
						{t(name)}
					</BreadcrumbLink>
				</BreadcrumbItem>
			))}
		</Breadcrumb>
	);
};
