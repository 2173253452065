import { useAuth0 } from '@auth0/auth0-react';
import { FC, useEffect } from 'react';
import { SitecoreUser } from '../../auth/SitecoreUser';
import {
	isProductionMode,
	targetedEnvironmentName,
} from '../../config/envConfig';
import { appSwitcherInterface } from './types';

const serverSettingsId = 'portal-server-settings';

export const AppSwitcher: FC = () => {
	const { getAccessTokenSilently, loginWithRedirect, user } =
		useAuth0<SitecoreUser>();

	// Init app switcher
	useEffect(() => {
		const initialize = () => {
			// Current implementation of app switcher only integrated with "staging" and "production" environments,
			// for other environments decided to show staging version of app switcher.
			if (!document.getElementById(serverSettingsId)) {
				const serverSettings = document.createElement('script');
				serverSettings.id = serverSettingsId;
				serverSettings.type = 'application/json';
				serverSettings.innerHTML = `{
                    "env": "${targetedEnvironmentName}"
                }`;

				document.body.appendChild(serverSettings);
			}

			const organization_id =
				user?.['https://auth.sitecorecloud.io/claims/org_id'] || '';
			const appSwitcher = document.querySelector(
				'app-switcher',
			) as unknown as appSwitcherInterface;

			if (!appSwitcher || !appSwitcher?.componentOnReady) {
				return;
			}

			appSwitcher.componentOnReady().then(() => {
				appSwitcher.init({
					getAccessToken: getAccessTokenSilently,
					loginWithRedirect: loginWithRedirect,
					organizationId: organization_id,
					environment: targetedEnvironmentName,
					isDevMode: !isProductionMode(),
					applicationName: 'Xm Cloud Deploy UI',
				});
			});
		};

		initialize();
	}, [loginWithRedirect, getAccessTokenSilently, user]);

	return (
		<div style={{ zIndex: 1 }} data-testid="app-switcher">
			<app-switcher></app-switcher>
		</div>
	);
};
