import { useToast } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { useParams } from 'react-router';
import { environmentVariable } from '../../../../../@xmcloud/core/messages/en';
import { useUpsertEnvironmentVariableMutation } from '../../../../services/environments';
import EditCreateVariableForm from '../../components/EditCreateVariableForm';
import { useQueryClient } from 'react-query';
import config from '../../../../config/config';

const {
	targetRH: targetRHtxt,
	targetAll: targetAlltxt,
	createdSuccessfully,
} = environmentVariable;

const { environment_variables } = config.environments;

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
}

const CreateVariable: React.FC<Props> = ({ openModal, setOpenModal }) => {
	const { environmentId } = useParams<{ environmentId: string }>();
	const queryClient = useQueryClient();
	const toast = useToast();
	const { mutate: createVariable, isLoading } =
		useUpsertEnvironmentVariableMutation();

	const handleCreateVariable = (values: any) => {
		createVariable(
			{
				environmentId: environmentId!,
				variableName: values.name,
				data: {
					secret: values.secret,
					value: values.value,
					target:
						values.target === targetRHtxt
							? values.renderingHost
							: values.target === targetAlltxt
								? null
								: values.target,
				},
			},
			{
				onSuccess: (data, variables) => {
					queryClient.invalidateQueries(
						environment_variables.queryKey(variables.environmentId),
					);
					setOpenModal(false);
					toast({
						status: 'success',
						description: t(createdSuccessfully),
					});
				},
			},
		);
	};

	return (
		<EditCreateVariableForm
			{...{
				openModal,
				setOpenModal,
				onSubmit: handleCreateVariable,
				isLoading,
			}}
		/>
	);
};

export default CreateVariable;
